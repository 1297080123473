import { Box, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'

import { Button, Modal } from '@percent/lemonade'
import { ErrorIcon } from '@percent/workplace-giving/common/components/ErrorIcon/ErrorIcon'
import { ModalProps } from 'libs/shared/ui-lemonade/src/components/modal'

type UnexpectedErrorModalProps = {
  message: string
  description?: string
  onClose: VoidFunction
} & Pick<ModalProps, 'open'>

export function UnexpectedErrorModal({ open, onClose, message, description }: UnexpectedErrorModalProps) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()

  return (
    <Modal onClose={onClose} open={open}>
      <Box
        style={{
          padding: '3rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <ErrorIcon />
        <Text
          style={{
            margin: '1rem 0',
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 600
          }}
        >
          {message}
        </Text>
        <Text
          style={{
            marginBottom: '2rem',
            textAlign: 'center',
            fontSize: '0.875rem',
            color: theme.neutral300
          }}
        >
          {description || t('workplace_giving.errors.tryAgainLater')}
        </Text>
        <Button variant="primary" onPress={onClose}>
          {t('workplace_giving.common.done')}
        </Button>
      </Box>
    </Modal>
  )
}
