import react from 'react'
import { Box } from '@mantine/core'

import { useAuth, useFeatureFlag } from '../hooks'

import { Spinner } from '@percent/lemonade'
import { AuthorizedAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { Feature } from '@percent/workplace-giving/providers/SplitSdkProvider'

export type PartnerScope =
  | 'workplace_giving'
  | 'donation_link'
  | 'volunteering'
  | 'donation_matching'
  | 'donation_wallet'
  | 'gamification'

export type AuthChecker = (props: { state: AuthorizedAuthState; featureFlags: Record<Feature, boolean> }) => boolean

export const withAuth =
  (...checkers: AuthChecker[]) =>
  (Component: react.ComponentType, OrComponent?: react.ComponentType) =>
    function RolesAuth(props: any) {
      const { state } = useAuth()
      const featureFlags = useFeatureFlag()

      if (state.status === 'loading' || state.status === 'first_load' || state.status === 'unauthorized') {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh'
            }}
          >
            <Spinner size={8} />
          </Box>
        )
      }

      if (state.status === 'authorized' && checkers.every(checker => checker({ state, featureFlags }))) {
        return <Component {...props} />
      }

      if (OrComponent) {
        return <OrComponent {...props} />
      }

      return null
    }

export const permission =
  (...allowedPermissions: string[]): AuthChecker =>
  ({ state }) =>
    allowedPermissions.some(p => state.permissions.includes(p))

export const scope =
  (...requiredScopes: PartnerScope[]): AuthChecker =>
  ({ state }) =>
    requiredScopes.every(s => state.partner.scopes.map(({ scopeName }) => scopeName).includes(s))

export const feature =
  (...requiredFeatures: Feature[]): AuthChecker =>
  ({ featureFlags }) =>
    requiredFeatures.every(f => featureFlags?.[f] === true)

export const withWorkplaceGivingAccess = withAuth(permission('wg_dashboard:access'), scope('workplace_giving'))
