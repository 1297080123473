import { TimeLogDetailDto } from '../../opportunity/dto'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export type VolunteeringActivityDetails = TimeLogDetailDto & {
  organisation: {
    id: string
    name: string
    logo: string | null
    countryCode: string | null
  } | null
}

export const getVolunteeringActivity: QueryFn<[string, string], VolunteeringActivityDetails> = async (
  client,
  [_, timeLogId]
) => {
  const res = await client.get<{ data: VolunteeringActivityDetails }>(`/account/volunteering-time-logs/${timeLogId}`, {
    withCredentials: true
  })

  return res.data.data
}
