import { Box } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom-v6'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAnalytics } from '../../hooks/useAnalytics/useAnalytics'
import { useMatchingBudget } from '../../hooks/useMatchingBudget/useMatchingBudget'
import { useMatchRequestForm } from '../../hooks/useMatchRequestForm/useMatchRequestForm'
import { usePermission } from '../../hooks/usePermission/usePermission'
import { permission, scope } from '../../hoc/withPermissions'

import { Permissions } from '@percent/workplace-giving/constants/permissions'
import { ButtonText, Menu } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import classes from './OrganisationCardActions.module.scss'
import { Organisation } from '@percent/workplace-giving/api/featured/featured.types'

type OrganisationCardActionsProps = Readonly<{
  organisation: Organisation
}>

export function OrganisationCardActions({ organisation }: OrganisationCardActionsProps) {
  const { track } = useAnalytics()
  const { dispatch } = useMatchRequestForm()
  const navigation = useNavigate()
  const { t } = useTranslation()
  const has = usePermission()
  const { data: matching } = useMatchingBudget()

  const isMobile = !!useMediaQuery('(max-width: 768px)')

  const searchParamsFromOrg = new URLSearchParams({
    organisationId: organisation.id
  }).toString()

  const availableActions = useMemo(
    () => [
      ...(has(scope('donation_matching')) && (matching?.remainingAmount?.amount ?? 0) > 0
        ? [
            {
              key: 'submitReceipt',
              name: t('workplace_giving.navigation.submitReceipt'),
              onClick: () => {
                track({
                  event: 'Donation Match Request Start',
                  properties: {
                    origin: 'Search Result Card'
                  }
                })
                dispatch({ type: 'OPEN', params: { defaultOrg: organisation } })
              }
            }
          ]
        : []),
      ...(has(scope('volunteering'))
        ? [
            {
              key: 'createOpportunity',
              name: t('workplace_giving.navigation.createOpportunity'),
              onClick: () => {
                track({
                  event: 'Volunteering Opportunity Create Start',
                  properties: {
                    origin: 'Search Result Card'
                  }
                })
                navigation({
                  pathname: `${AppRoute.CREATE_OPPORTUNITY}`,
                  search: searchParamsFromOrg
                })
              }
            },
            {
              key: 'logTime',
              name: t('workplace_giving.navigation.logTime'),
              onClick: () => {
                track({
                  event: 'Volunteering Time Logging Start',
                  properties: {
                    origin: 'Search Result Card'
                  }
                })
                navigation({
                  pathname: AppRoute.LOG_VOLUNTEERING_ACTIVITY,
                  search: searchParamsFromOrg
                })
              }
            }
          ]
        : []),
      ...(has(scope('donation_link'), permission(Permissions.FundraiserWrite))
        ? [
            {
              key: 'createFundraiser',
              name: t('workplace_giving.navigation.createFundraiser'),
              onClick: () => {
                track({
                  event: 'Donation Fundraiser Create Start',
                  properties: {
                    origin: 'Search Result Card'
                  }
                })
                navigation({
                  pathname: `${AppRoute.FUNDRAISER_WIZARD}`,
                  search: searchParamsFromOrg
                })
              }
            }
          ]
        : [])
    ],
    [dispatch, has, matching?.remainingAmount?.amount, navigation, organisation, searchParamsFromOrg, t, track]
  )

  return (
    <Box className={classes.ActionsWrapper}>
      {!isMobile ? (
        <Box className={classes.ButtonsWrapper}>
          {availableActions.slice(0, 2).map(action => (
            <ButtonText key={action.key} variant="primary" onPress={action.onClick}>
              {action.name}
            </ButtonText>
          ))}
        </Box>
      ) : null}
      {availableActions.length > 2 || isMobile ? (
        <Menu
          title={t('workplace_giving.common.more')}
          customTriggerComponent={
            <button className={classes.MenuBtn} type="button">
              {t('workplace_giving.common.more')}
            </button>
          }
          onSelect={itemKey => availableActions.find(({ key }) => key === itemKey)?.onClick()}
          sections={[
            {
              items: [
                ...(isMobile
                  ? availableActions.map(action => ({
                      key: action.key,
                      label: action.name
                    }))
                  : availableActions.slice(2, availableActions.length).map(action => ({
                      key: action.key,
                      label: action.name
                    })))
              ]
            }
          ]}
        />
      ) : null}
    </Box>
  )
}
