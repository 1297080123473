/* eslint-disable jsx-a11y/accessible-emoji */
import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'
import { Link } from 'react-router-dom-v6'

import { Button, Modal, Checkbox, FormField, TextInput, Select } from '@percent/lemonade'
import { getStyles } from './OnboardingPopup.styles'
import { useAuth, useWindowDimensions } from '@percent/workplace-giving/common/hooks'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { config } from '@percent/workplace-giving/config/config'
import { OnboardingPopupSkeleton } from './OnboardingPopupSkeleton'
import { OnboardingPopupModalProps } from './OnboardingPopup.types'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function OnboardingPopupModal({
  displayOnboardingPopup,
  showOnboardingPopupSkeleton,
  handleSubmit,
  touched,
  errors,
  error,
  handleChange,
  isSubmitting,
  buttonDisabled,
  handleBlur,
  values,
  currencyOptions,
  setFieldValue,
  defaultCurrencyOption,
  showCurrencyOptions
}: OnboardingPopupModalProps) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const user = getAccountFromAuthState(state)
  const isDesktop = useMediaQuery('(min-width: 600px)')
  const { width: windowWidth } = useWindowDimensions()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Modal open={displayOnboardingPopup} blur width={isDesktop ? 552 : windowWidth - 48}>
      <Box sx={Styles.Container}>
        <Box sx={Styles.ContentWrapper}>
          {showOnboardingPopupSkeleton ? (
            <OnboardingPopupSkeleton withPreferredNameField={!user?.preferredName} />
          ) : (
            <>
              <Box sx={Styles.Header}>
                <Box>
                  <Typography component="h2" sx={Styles.Title}>
                    {t('workplace_giving.onboardingPopup.title')}
                  </Typography>
                  <Typography component="p" sx={Styles.Description}>
                    {t('workplace_giving.onboardingPopup.description')}
                  </Typography>
                </Box>
                <Typography sx={Styles.Icon}>
                  <Box sx={Styles.Wave} component="span" role="img" aria-label="Waving hand">
                    👋
                  </Box>
                </Typography>
              </Box>
              <Box sx={Styles.Form} component="form" onSubmit={handleSubmit}>
                <FormField
                  label={t('workplace_giving.onboardingPopup.label.fullName')}
                  status={touched.fullName && errors.fullName ? 'danger' : 'default'}
                  statusMessage={errors.fullName}
                  data-testid="fullName"
                  necessity="required"
                  description={t('workplace_giving.onboardingPopup.input.description.fullName')}
                >
                  <TextInput
                    name="fullName"
                    value={values.fullName}
                    placeholder={t('workplace_giving.onboardingPopup.placeholder.fullName')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    readOnly={!!user?.fullName}
                  />
                </FormField>

                {!user?.preferredName ? (
                  <FormField
                    label={t('workplace_giving.onboardingPopup.label.preferredName')}
                    status={touched.preferredName && errors.preferredName ? 'danger' : 'default'}
                    statusMessage={errors.preferredName}
                    data-testid="preferredName"
                    necessity="required"
                    description={t('workplace_giving.onboardingPopup.input.description.preferredName')}
                  >
                    <TextInput
                      name="preferredName"
                      data-testid="preferredName"
                      value={values.preferredName}
                      placeholder={t('workplace_giving.onboardingPopup.placeholder.preferredName')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormField>
                ) : null}

                {error ? (
                  <Typography variant="body2" sx={Styles.ErrorMessage}>
                    {error}
                  </Typography>
                ) : null}

                {showCurrencyOptions ? (
                  <FormField
                    label={t('workplace_giving.onboardingPopup.label.preferredCurrency')}
                    status={touched.preferredCurrency && errors.preferredCurrency ? 'danger' : 'default'}
                    statusMessage={errors.preferredCurrency}
                    data-testid="preferredCurrency"
                    necessity="required"
                    description={t('workplace_giving.onboardingPopup.input.description.preferredCurrency')}
                  >
                    <Select
                      onChange={event => {
                        setFieldValue('preferredCurrency', event.value)
                      }}
                      defaultValue={defaultCurrencyOption}
                      options={currencyOptions!}
                      data-testid="currencySelect"
                      showPrefixForSelectedOption
                    />
                  </FormField>
                ) : null}

                <Box sx={Styles.Spacer} />

                <Typography component="p" sx={Styles.TermsInfo}>
                  {t('workplace_giving.onboardingPopup.termsAndConditionsInfo')}
                </Typography>

                <Checkbox
                  name="termsAndConditions"
                  dataTestId="terms-checkbox"
                  errorMessage={
                    touched.termsAndConditions && errors.termsAndConditions ? errors.termsAndConditions : ''
                  }
                  active={values.termsAndConditions}
                  onBlur={handleBlur}
                  label={
                    <Trans
                      t={t}
                      i18nKey="workplace_giving.onboardingPopup.termsAndConditionsCheckbox"
                      components={{
                        1: (
                          <Link
                            to={config.terms['workplace:terms:2024-01-01']}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        2: (
                          <Link
                            to={config.terms['percent:privacy:2024-01-01']}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        )
                      }}
                    />
                  }
                  necessity="required"
                  variant="default"
                  onChange={handleChange}
                />

                <Box sx={Styles.ButtonsWrapper}>
                  <Button
                    data-testid="submitButton"
                    variant="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={buttonDisabled}
                  >
                    {t('workplace_giving.onboardingPopup.continueButton')}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
