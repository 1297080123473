import { Box } from '@mantine/core'
import React from 'react'

import classes from './Page.module.scss'

export function Page({ children, testId }: Readonly<{ children: React.ReactNode; testId?: string }>) {
  return (
    <Box data-testid={testId} className={classes.PageWrapper}>
      {children}
    </Box>
  )
}
