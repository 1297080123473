import { useNavigate } from 'react-router-dom-v6'
import { useTranslation } from 'react-i18next'
import { Box, Skeleton, Text } from '@mantine/core'

import classes from '../Campaign.module.scss'

import { FeaturedCard, FundraiserCardSkeleton } from '@percent/domain/giving'
import { useInfiniteQuery } from '@percent/workplace-giving/common/hooks'
import { getCampaignFundraisers } from '@percent/workplace-giving/api/campaigns/getCampaignFundraisers/getCampaignFundraisers'
import { CardType } from 'libs/domain/giving/src/components/featured-card/featured-card.types'
import { mapInfiniteData } from '@percent/workplace-giving/utils/mapInfiniteData'

export function CampaignFundraisers({ campaignId }: Readonly<{ campaignId: string }>) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { data, isFetching } = useInfiniteQuery(
    ['getCampaignFundraisers', { campaignId, pageSize: 10 }],
    getCampaignFundraisers,
    { staleTime: 1000 * 20 }
  )

  const mappedData = mapInfiniteData(data)

  if (isFetching) {
    return (
      <Box className={classes.SectionWrapper} data-testid="campaign-fundraisers-section-loader">
        <Skeleton height="2rem" width="55%" />
        <Box className={classes.SectionTilesContentWrapper}>
          <FundraiserCardSkeleton />
        </Box>
      </Box>
    )
  }

  if (mappedData) {
    return (
      <Box className={classes.SectionWrapper} data-testid="campaign-fundraisers-section">
        <Text className={classes.SectionTitle}>{t('workplace_giving.campaigns.fundraisers')}</Text>
        <Box className={classes.SectionTilesContentWrapper}>
          {mappedData.pages.map(p =>
            p.map(f => (
              <FeaturedCard
                key={f.id}
                type={CardType.SUGGESTED_FUNDRAISER}
                actionButton={() => {
                  navigate(`/fundraisers/${f.id}`)
                }}
                title={f.title}
                organisationName={f.organisation.name}
                organisationLogo={f.organisation?.imageUrl}
                imgSrc={f.imageUrl}
                locale={i18n.language}
                goal={f.goal}
                total={f.total}
              />
            ))
          )}
        </Box>
      </Box>
    )
  }

  return null
}
