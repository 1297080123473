import type { ListResponse } from '../../goodstack'

import type { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { CampaignFundraiser, GetCampaignFundraisersQueryParams } from './getCampaignFundraisers.types'

export const getCampaignFundraisers: InfiniteQueryFn<
  [string, GetCampaignFundraisersQueryParams],
  string,
  ListResponse<CampaignFundraiser>
> = async (client, [_, { campaignId, direction = 'ASC', pageSize = 25 }], cursor) =>
  (
    await (cursor
      ? client.get<ListResponse<CampaignFundraiser>>(cursor, {
          withCredentials: true
        })
      : client.get<ListResponse<CampaignFundraiser>>(`/giving-campaign/${campaignId}/fundraisers`, {
          params: { direction, pageSize },
          withCredentials: true
        }))
  ).data
