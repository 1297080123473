import { useTranslation } from 'react-i18next'

import { useMoneyFormat } from '@percent/workplace-giving/common/hooks'
import { useMatchedDonationCard } from './useMatchedDonationCard'
import { DashboardMetricCard } from '@percent/workplace-giving/common/components/DashboardMetricCard/DashboardMetricCard'

export function MatchedDonationCard(): JSX.Element {
  const { isLoading, matchDonation, partnerName } = useMatchedDonationCard()
  const { moneyFormat } = useMoneyFormat()
  const { t } = useTranslation()

  return (
    <DashboardMetricCard
      title={t('workplace_giving.totalDonated.matchedBy', { partnerName })}
      value={moneyFormat(matchDonation)}
      isLoading={isLoading}
      icon="donate-outline"
      testId="matched-donation-card"
    />
  )
}
