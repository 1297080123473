import styled from 'styled-components'

import { Icon } from '@percent/lemonade'

const StyledClearBtn = styled.button`
  background: transparent;
  border: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export type ClearButtonProps = {
  handleClick: VoidFunction
  testId?: string
}

export function ClearButton({ handleClick, testId }: ClearButtonProps) {
  return (
    <StyledClearBtn data-testid={testId} onClick={handleClick}>
      <Icon name="close" color="gray300" size={4} />
    </StyledClearBtn>
  )
}
