import { theme as lemonadeTheme } from '@percent/lemonade'

export const Styles = {
  Title: {
    marginBottom: lemonadeTheme.sizes[2],
    fontSize: lemonadeTheme.sizes[6],
    lineHeight: lemonadeTheme.sizes[8],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  },
  Description: {
    marginBottom: lemonadeTheme.sizes[6],
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6]
  }
}
