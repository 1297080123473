import { type FocusEvent, type FormEvent } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormikErrors, FormikTouched } from 'formik'

import { getStyles } from './LogVolunteeringActivityForm.styles'
import { SelectOptionCard } from '@percent/workplace-giving/common/components/SelectOptionCard/SelectOptionCard'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import {
  useLogVolunteeringActivityForm,
  type LogVolunteeringActivityFormFields,
  type UseLogVolunteeringActivityFormProps
} from './useLogVolunteeringActivityForm'
import { LogOnPlatformActivityForm } from './LogOnPlatformActivityForm'
import { LogOffPlatformActivityForm } from './LogOffPlatformActivityForm'
import { VolunteeringTimeLogType } from '@percent/workplace-giving/api/opportunity/dto'
import { VolunteeringActivityDetails } from '@percent/workplace-giving/api/volunteering/getVolunteeringActivity/getVolunteeringActivity'
import { Organisation } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'

type LogVolunteeringActivityFormProps = UseLogVolunteeringActivityFormProps &
  Readonly<{
    touched: FormikTouched<LogVolunteeringActivityFormFields>
    errors: FormikErrors<LogVolunteeringActivityFormFields>
    handleChange: (e: FormEvent<HTMLInputElement>) => void
    handleBlur: <A = HTMLInputElement>(e: FocusEvent<A>) => void
    editedTimeLogDetails?: VolunteeringActivityDetails
    defaultOrg?: Organisation
  }>

export function LogVolunteeringActivityForm({
  errors,
  touched,
  handleBlur,
  handleChange,
  editedTimeLogDetails,
  ...props
}: Readonly<LogVolunteeringActivityFormProps>) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const {
    alpha3CountryCodes,
    activitiesOptions,
    isFetching,
    isFetchingRegisteredOpportunities,
    organisationQuery,
    registeredOpportunitiesQuery,
    registeredOpportunitiesSearchResults,
    resetOrgIdFieldAfterCountryChange,
    searchCountryCode,
    searchResults,
    setOrganisationQuery,
    setSearchCountryCode,
    setRegisteredOpportunitiesQuery,
    isLoading,
    skillsOptions,
    defaultCountryValue,
    registeredOpportunitiesCount,
    defaultOrganisationValue
  } = useLogVolunteeringActivityForm({ ...props })
  const { values, setFieldValue } = props

  const isEditMode = !!editedTimeLogDetails

  return (
    <Box component="form" sx={Styles.Form} onSubmit={event => event.preventDefault()}>
      {!isEditMode ? (
        <Box sx={Styles.OptionsCardsBox}>
          <SelectOptionCard
            testId="offPlatformVariant"
            icon="volunteering"
            title={t('workplace_giving.volunteering.activityForm.offPlatformLabel')}
            description={t('workplace_giving.volunteering.activityForm.offPlatformDescription')}
            active={values.platform === VolunteeringTimeLogType.OffPlatform}
            onClick={() => {
              setFieldValue('platform', VolunteeringTimeLogType.OffPlatform)
            }}
          />
          <SelectOptionCard
            testId="onPlatformVariant"
            icon="volunteering-calendar"
            title={t('workplace_giving.volunteering.activityForm.onPlatformLabel')}
            description={t('workplace_giving.volunteering.activityForm.onPlatformDescription')}
            active={values.platform === VolunteeringTimeLogType.OnPlatform}
            onClick={() => {
              setFieldValue('platform', VolunteeringTimeLogType.OnPlatform)
            }}
          />
        </Box>
      ) : undefined}

      {values.platform === VolunteeringTimeLogType.OnPlatform ? (
        <LogOnPlatformActivityForm
          {...props}
          registeredOpportunitiesCount={registeredOpportunitiesCount}
          registeredOpportunitiesSearchResults={
            isEditMode && editedTimeLogDetails?.opportunity
              ? [
                  {
                    value: editedTimeLogDetails.opportunity.id,
                    label: editedTimeLogDetails.opportunity.name
                  }
                ]
              : registeredOpportunitiesSearchResults
          }
          setRegisteredOpportunitiesQuery={setRegisteredOpportunitiesQuery}
          registeredOpportunitiesQuery={registeredOpportunitiesQuery}
          isFetchingRegisteredOpportunities={isFetchingRegisteredOpportunities}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          isEditMode={isEditMode}
        />
      ) : (
        <LogOffPlatformActivityForm
          {...props}
          alpha3CountryCodes={alpha3CountryCodes}
          activitiesOptions={activitiesOptions}
          isFetching={isFetching}
          organisationQuery={organisationQuery}
          searchCountryCode={searchCountryCode}
          searchResults={
            isEditMode && editedTimeLogDetails?.organisation
              ? [
                  {
                    value: editedTimeLogDetails.organisation.id,
                    label: editedTimeLogDetails.organisation.name
                  }
                ]
              : searchResults
          }
          setOrganisationQuery={setOrganisationQuery}
          setSearchCountryCode={setSearchCountryCode}
          defaultCountryValue={defaultCountryValue}
          resetOrgIdFieldAfterCountryChange={resetOrgIdFieldAfterCountryChange}
          isLoading={isLoading}
          skillsOptions={skillsOptions}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          isEditMode={isEditMode}
          defaultOrganisationValue={defaultOrganisationValue}
        />
      )}
    </Box>
  )
}
