import { useTranslation } from 'react-i18next'

import { formatMinutesToHours } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { useTotalVolunteeredCard } from './useTotalVolunteeredCard'
import { DashboardMetricCard } from '@percent/workplace-giving/common/components/DashboardMetricCard/DashboardMetricCard'

export function TotalVolunteeredCard(): JSX.Element {
  const { t } = useTranslation()
  const { isLoading, totalVolunteeredMinutes } = useTotalVolunteeredCard()

  const formattedTime =
    !isLoading && totalVolunteeredMinutes > 0
      ? formatMinutesToHours(totalVolunteeredMinutes, t)
      : `0 ${t('workplace_giving.common.hours')}`

  return (
    <DashboardMetricCard
      title={t('workplace_giving.volunteering.totalVolunteered.title')}
      value={formattedTime}
      isLoading={isLoading}
      icon="stopwatch-outline"
      testId="total-volunteered-card"
    />
  )
}
