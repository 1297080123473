/* eslint-disable no-underscore-dangle */
import { useNavigate, useSearchParams } from 'react-router-dom-v6'
import { InfiniteData } from '@tanstack/react-query'
import { useEffect, useRef, useMemo, useState } from 'react'
import throttle from 'lodash/throttle'
import { Box, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'

import { SearchResults } from '../SearchResults/SearchResults'
import { OrganisationSearchBar } from '../SearchBar/SearchBar'

import { useAuth } from '@percent/workplace-giving/common/hooks'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { SidePanel } from '@percent/workplace-giving/app/SidePanel/SidePanel'
import { VerifiedOrganisationSearchResult } from '@percent/workplace-giving/api/search/searchOrganisations/searchVerifiedOrganisations.types'
import countries from '@percent/workplace-giving/i18n/countries'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { useOrganisationSearch } from '@percent/workplace-giving/common/hooks/useOrganisationSearch/useOrganisationSearch'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import classes from './SearchPage.module.scss'

export function SearchPage() {
  const authState = useAuth()
  const { track } = useAnalytics()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isSmallDesktopScreen = useMediaQuery(queryWidthLessThan('xl'))
  const [searchParams, setSearchParams] = useSearchParams()
  const [scrolled, setScrolled] = useState(false)

  const query = searchParams.get('query') ?? undefined
  const code = searchParams.get('country') ?? undefined

  useEffect(() => {
    if (query === undefined) {
      navigate(AppRoute.HOME, { replace: true })
    }
  }, [query, navigate])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [searchParams])

  const scrolledRef = useRef(scrolled)
  const setScrolledValue = (data: boolean) => {
    scrolledRef.current = data
    setScrolled(data)
  }

  const handleScroll = throttle(() => {
    const scrolledFromTop = window.scrollY

    if (scrolledRef.current && scrolledFromTop > 0) {
      setScrolledValue(true)
    } else if (!scrolledRef.current && scrolledFromTop > 0) {
      setScrolledValue(true)
    } else {
      setScrolledValue(false)
    }
  }, 20)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const countryCode = useMemo(() => {
    if ((code && countries.isValid(code)) || code === '') {
      return code
    }

    const codeFromAuthState = getCountryCodeFromAuthState(authState.state)

    if (codeFromAuthState && countries.isValid(codeFromAuthState)) {
      return codeFromAuthState
    }

    return ''
  }, [authState.state, code])

  const { data, isFetching, fetchNextPage, hasNextPage, isError, replacedByGlobalSearch, hideLocalSearch } =
    useOrganisationSearch({
      query,
      countryCode,
      localCurrencyCode: getCountryCodeFromAuthState(authState.state)!
    })

  const mappedData: InfiniteData<VerifiedOrganisationSearchResult[]> | undefined = data && {
    ...data,
    pages: data.pages.map(page => page.data)
  }
  const totalResults = data && data.pages.length > 0 ? data.pages[0].totalResults : 0
  const hideSidePanel = (totalResults || totalResults === 0) && isSmallDesktopScreen

  const onCountryChange = (country: string) => {
    setSearchParams(prev => {
      prev.set('country', country)

      return prev
    })
  }
  const onQueryChange = (q: string) => {
    setSearchParams(prev => {
      prev.set('query', q)

      return prev
    })
  }

  useEffect(() => {
    if (query?.trim().length) {
      track({
        event: 'Donation Organisation Search',
        properties: {
          origin: 'Home',
          countryCode
        }
      })
    }
  }, [query, countryCode, track])

  return (
    <Page testId="search-page-wrapper">
      <Box className={classes.PageContainer}>
        <Title order={2} className={classes.Heading}>
          {t('workplace_giving.search.heading')}
        </Title>
        <OrganisationSearchBar
          query={query}
          onQueryChange={onQueryChange}
          countryCode={countryCode}
          onCountryChange={onCountryChange}
          scrolled={scrolled}
          fullWidth
        />
        <Box className={classes.SearchResultsWrapper} data-testid="search-results">
          <SearchResults
            searchTerm={query!}
            totalResults={totalResults}
            isLoading={isFetching}
            isError={isError}
            data={mappedData}
            hasMore={hasNextPage}
            loadMore={() => fetchNextPage()}
            countryCode={countryCode}
            setSearchParams={setSearchParams}
            replacedByGlobalSearch={replacedByGlobalSearch}
            userCountryCode={getCountryCodeFromAuthState(authState.state)}
            hideLocalSearch={hideLocalSearch}
          />
        </Box>
      </Box>
      {!hideSidePanel && <SidePanel />}
    </Page>
  )
}
