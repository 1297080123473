import { Styles } from '@percent/workplace-giving/types'

export const getStyles = (fullWidth = true) => ({
  Wrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    width: fullWidth ? '100%' : '60%',

    '& > button': {
      marginLeft: '8px'
    },
    '@media (min-width: 900px) and (max-width: 1050px)': {
      width: '100%'
    },

    '@media(max-width: 899px)': {
      flexDirection: 'column-reverse',
      width: '100%'
    }
  },
  Input: {
    width: 'calc(100% - 286px - 0.5rem)',

    '@media(max-width: 899px)': {
      width: '100%',
      marginTop: '8px'
    }
  },
  Select: (scrolled: boolean): Styles => ({
    width: '286px',
    marginLeft: '0.5rem',

    '@media(max-width: 899px)': {
      width: '100%',
      marginLeft: 0,
      transform: scrolled ? `translateY(-100%)` : 'translateY(0)',
      opacity: scrolled ? 0 : 1,
      transition: 'transform 0.4s linear, opacity 0.4s linear'
    }
  })
})
