import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom-v6'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { Styles } from '../LogVolunteeringActivity.styles'
import { LogVolunteeringActivityForm } from '../LogVolunteeringActivityForm/LogVolunteeringActivityForm'
import { LogVolunteeringActivityCancellationModal } from '../LogVolunteeringActivityCancellationModal'
import { LogVolunteeringActivitySuccessModal } from '../LogVolunteeringActivitySuccessModal'
import { LogVolunteeringFormType } from '../LogVolunteeringActivity'

import { FullPageFormLayout, Loader } from '@percent/lemonade'
import { useAuth, useLogger, useMutation, useQuery } from '@percent/workplace-giving/common/hooks'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { getVolunteeringActivity } from '@percent/workplace-giving/api/volunteering/getVolunteeringActivity/getVolunteeringActivity'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { editVolunteeringActivity } from '@percent/workplace-giving/api/volunteering/editVolunteeringActivity/editVolunteeringActivity'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { WizardHeader } from '@percent/workplace-giving/common/components/WizardHeader/WizardHeader'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { VolunteeringTimeLogType } from '@percent/workplace-giving/api/opportunity/dto'

type EditVolunteeringFormType = {
  date?: Date
  minutes?: number
  hours?: number
  organisationCountry?: string
  organisationId?: string
  activities?: string[]
  platform: VolunteeringTimeLogType
  opportunityId?: string
  skills: string[]
}

export function EditVolunteeringActivity() {
  const { track } = useAnalytics()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { timeLogId } = useParams()
  const { logError } = useLogger()
  const authState = useAuth()
  const defaultCountry = getCountryCodeFromAuthState(authState.state)!

  const { validateNumber, validateString, validateDate } = useSharedValidationRules()

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openDetailsFetchingError, setOpenDetailsFetchingError] = useState(false)
  const [openCancellationModal, setOpenCancellationModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState<EditVolunteeringFormType>({
    date: undefined,
    minutes: 0,
    hours: undefined,
    organisationCountry: defaultCountry || '',
    organisationId: '',
    activities: [],
    platform: VolunteeringTimeLogType.OffPlatform,
    opportunityId: '',
    skills: []
  })

  const navigateBackToMyImpact = () => {
    navigate(AppRoute.MY_IMPACT, { state: { activeTab: 'volunteer' } })
  }

  const {
    data: activityLogData,
    isSuccess,
    isLoading,
    isError,
    error: activityLogFetchingError
  } = useQuery(['getVolunteeringActivity', timeLogId as string], getVolunteeringActivity, {
    enabled: !!timeLogId
  })

  useEffect(() => {
    if (isError && activityLogFetchingError) {
      logError(activityLogFetchingError)
      setOpenDetailsFetchingError(true)
    }
  }, [activityLogFetchingError, isError, logError])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isSuccess) {
      return setInitialFormValues({
        date: new Date(activityLogData.date),
        minutes: activityLogData.minutes % 60 || 0,
        hours: Math.floor(activityLogData.minutes / 60),
        organisationCountry: activityLogData.organisation?.countryCode || '',
        organisationId: activityLogData.organisation?.id,
        activities: activityLogData.activities?.map(({ id }) => id),
        platform: activityLogData.type,
        opportunityId: activityLogData?.opportunity?.id,
        skills: activityLogData?.skills?.map(({ id }) => id)
      })
    }
  }, [isSuccess, activityLogData, timeLogId])

  const { mutateAsync } = useMutation({
    mutationFn: editVolunteeringActivity,
    onSuccess: res => {
      if (okResponse(res)) {
        track({
          event: 'Volunteering Time Log Edit Completed',
          properties: {
            timeLogId
          }
        })

        return setOpenSuccessModal(true)
      }

      return setOpenErrorModal(true)
    },
    onError: error => {
      setOpenErrorModal(true)
      logError(error)
    }
  })

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty
  } = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.object().shape({
        date: validateDate(),
        hours: validateNumber({ mustBePositive: false, min: 0, max: 24, canEqualMax: true }),
        minutes: validateNumber({ mustBePositive: false, min: 0, max: 59, canEqualMax: true }).when('hours', {
          is: (hours: unknown) => typeof hours === 'number' && hours === 24,
          then: schema => schema.max(0, t('workplace_giving.validation.maxOrEqualAmount', { max: 0 }))
        }),
        platform: Yup.string().oneOf(Object.values(VolunteeringTimeLogType)),
        opportunityId: validateString({ optional: values.platform === VolunteeringTimeLogType.OffPlatform }),
        organisationCountry: validateString({ optional: true }),
        organisationId: validateString({ optional: true }),
        activities: Yup.array()
          .of(Yup.string())
          .when(['platform'], {
            is: VolunteeringTimeLogType.OffPlatform,
            then: schema =>
              schema
                .min(1, t('workplace_giving.validation.requiredField'))
                .required(t('workplace_giving.validation.requiredField'))
          }),
        skills: Yup.array().of(Yup.string()).optional()
      }),
    onSubmit: data => {
      const { date, minutes, hours, platform } = data as unknown as LogVolunteeringFormType
      const baseProps = {
        date,
        minutes: (hours || 0) * 60 + (minutes || 0),
        timeLogId: timeLogId as string
      }
      const props =
        platform === VolunteeringTimeLogType.OffPlatform
          ? {
              ...baseProps,
              platform,
              organisationId: data?.organisationId?.length ? data.organisationId : undefined,
              activities: data.activities as string[],
              skills: data.skills as string[]
            }
          : {
              ...baseProps,
              platform,
              opportunityId: data.opportunityId as string
            }

      return mutateAsync([props])
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  const formValid = isValid && dirty && !isSubmitting

  if (((timeLogId && isLoading) || (timeLogId && !values.date)) && !isError) {
    return <Loader />
  }

  return (
    <FullPageFormLayout
      actionsHeader={
        <WizardHeader
          title={t('workplace_giving.volunteering.activityForm.editYourActivity')}
          onCancel={() => {
            if (dirty) {
              setOpenCancellationModal(true)
            } else {
              navigateBackToMyImpact()
            }
          }}
          onPublish={handleSubmit}
          isPublishDisabled={!formValid}
          isLoading={isSubmitting}
          publishBtnCopy={t('workplace_giving.common.saveChanges')}
        />
      }
    >
      <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.activityForm.title')}</Typography>
      <Typography sx={Styles.Description}>{t('workplace_giving.volunteering.activityForm.description')}</Typography>

      <LogVolunteeringActivityForm
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        editedTimeLogDetails={activityLogData}
      />

      <LogVolunteeringActivityCancellationModal
        open={openCancellationModal}
        onClose={() => setOpenCancellationModal(false)}
        isEditMode
      />
      <LogVolunteeringActivitySuccessModal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        isEditMode
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.api.editVolunteeringTime.title')}
        description={t('workplace_giving.errors.api.editVolunteeringTime.description')}
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.somethingWentWrong')}
        open={openDetailsFetchingError}
        onClose={() => {
          setOpenDetailsFetchingError(false)
          navigateBackToMyImpact()
        }}
      />
    </FullPageFormLayout>
  )
}
