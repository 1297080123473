import { Box } from '@mantine/core'
import { clsx } from '@mantine/styles'
import { useFormik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Link } from 'react-router-dom-v6'

import { SignInCard } from '../SignInCard/SignInCard'
import classes from '../SignInCard/SignInCard.module.scss'

import { Button, FormField, Spinner, Text, TextInput } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { forgotPassword } from '@percent/workplace-giving/api/auth/forgotPassword/forgotPassword'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'

export function ForgotPassword() {
  const { t } = useTranslation()
  const { validateEmail } = useSharedValidationRules()

  const { mutateAsync, isLoading, isSuccess, isError } = useMutation({
    mutationFn: forgotPassword
  })

  const { errors, values, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: validateEmail
      }),
    onSubmit: async ({ email }) => {
      await mutateAsync({ email })
    }
  })

  return (
    <SignInCard
      title={
        isSuccess ? t('workplace_giving.forgotPassword.success.title') : t('workplace_giving.forgotPassword.title')
      }
      description={
        isSuccess
          ? t('workplace_giving.forgotPassword.success.description')
          : t('workplace_giving.forgotPassword.description')
      }
    >
      {isLoading ? (
        <Box className={classes.CenteredFlexBox}>
          <Spinner size="l" />
        </Box>
      ) : isSuccess ? (
        <Box className={clsx(classes.LinksWrapper, classes.LinksWrapperCentered)}>
          <Link to={AppRoute.SIGN_IN}>{t('workplace_giving.forgotPassword.nowSignInHere')}</Link>
        </Box>
      ) : (
        <Box component="form" className={classes.Form} onSubmit={handleSubmit}>
          <FormField
            label={t('workplace_giving.login.email.label')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
            necessity="required"
          >
            <TextInput
              name="email"
              value={values.email}
              placeholder={t('workplace_giving.login.email.placeholder')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>

          {isError ? <Text className={classes.ErrorMessage}>{t('workplace_giving.errors.unexpected')}</Text> : null}

          <Button variant="primary" type="submit">
            {t('workplace_giving.forgotPassword.sendEmail')}
          </Button>

          <Box className={clsx(classes.LinksWrapper, classes.LinksWrapperCentered)}>
            <Text>
              <Trans
                t={t}
                i18nKey="workplace_giving.forgotPassword.lookingForSignIn"
                components={[<Link to={AppRoute.SIGN_IN} />]}
              />
            </Text>
          </Box>
        </Box>
      )}
    </SignInCard>
  )
}
