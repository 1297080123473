import { InfiniteData } from '@tanstack/react-query'

import { ListResponse } from '../api/goodstack'

export const mapInfiniteData = <T>(data?: InfiniteData<ListResponse<T>>): InfiniteData<T[]> | undefined => {
  return data
    ? {
        ...data,
        pages: data.pages.map(page => page.data)
      }
    : undefined
}
