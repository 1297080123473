import { useTranslation } from 'react-i18next'

import { IllustratedMessage } from '@percent/lemonade'
import classes from './GenericError.module.scss'

type GenericErrorProps = {
  title?: string
  description?: string
}

export function GenericError({ title, description }: GenericErrorProps) {
  const { t } = useTranslation()

  return (
    <div className={classes.ErrorContainer} data-testid="generic-error-component">
      <div className={classes.ErrorWrapper}>
        <IllustratedMessage
          illustration="general-error"
          title={title ?? t('workplace_giving.errors.genericError.title')}
          description={description ?? t('workplace_giving.errors.genericError.description')}
        />
      </div>
    </div>
  )
}
