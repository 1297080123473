import styled from 'styled-components'
import {
  BudgetPartName,
  ColorLegendProps,
  BudgetDetailsProps,
} from './budget-details.types'
import { ProgressBar, Tooltip } from '@percent/lemonade'

const Card = styled.div`
  ${({ theme }) =>
    `
    width: 100%;
    padding-bottom: ${theme.sizes[6]}
  `}
`

const NoBudgetTitle = styled.p`
  ${({ theme }) =>
    `
    margin: 0 0 ${theme.sizes[2]} 0;
    color: ${theme.colors.black};
    font-size:  ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[6]};
    font-weight: ${theme.fontWeights.medium}
  `}
`

const NoBudgetDescription = styled.p`
  ${({ theme }) =>
    `
    margin: 0;
    color: ${theme.colors.gray300};
    font-size:  ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[6]};
  `}
`

const DetailsWrapper = styled.div`
  ${({ theme }) =>
    `
    margin: 0 0 ${theme.sizes[2]} 0;
`}
`

const FlexBox = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`

const FlexContainer = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    flex-flow: row;
    justify-content: space-between;


    &:first-of-type {
      margin: 0 0 ${theme.sizes[2]} 0;

      p:first-of-type {
        font-weight: ${theme.fontWeights.medium};
      }
    }

    p {
      margin: 0;
      font-size: ${theme.fontSizes.bodySmall};
      line-height: ${theme.sizes[6]};
      color: ${theme.colors.black};
    }
  `}
`

const ColorLegend = styled.span<ColorLegendProps>`
  ${({ theme, $variant }) =>
    `
    width: ${theme.sizes[2]};
    height: ${theme.sizes[2]};
    margin-right: ${theme.sizes[2]};
    vertical-align: center;
    border-radius: ${theme.borderRadii.small};
    background-color: ${
      $variant === BudgetPartName.USED
        ? theme.colors.primary400
        : $variant === BudgetPartName.PENDING
        ? theme.colors.primary600
        : theme.colors.gray90
    };
  `}
`

const Info = styled.p`
  ${({ theme }) =>
    `
    margin: ${theme.sizes[2]} 0 0;
    color: ${theme.colors.gray600};
    font-size: ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[6]};
  `}
`

export const BudgetDetails = ({
  noBudgetLeft,
  noBudgetTitle,
  noBudgetDescription,
  totalBudget,
  usedBudget,
  pendingBudget,
  remainingBudget,
  isBudgetTouched,
  usedPercentage,
  pendingPercentage,
  resetInfo,
}: BudgetDetailsProps) => {
  const tooltipContent = (
    <DetailsWrapper>
      <FlexContainer>
        <p>{totalBudget.name}</p>
        <p>{totalBudget.value}</p>
      </FlexContainer>
      {isBudgetTouched ? (
        <>
          <FlexContainer>
            <FlexBox>
              <ColorLegend $variant={BudgetPartName.USED} />
              <p>{usedBudget.name}</p>
            </FlexBox>
            <p>{usedBudget.value}</p>
          </FlexContainer>
          <FlexContainer>
            <FlexBox>
              <ColorLegend $variant={BudgetPartName.PENDING} />
              <p>{pendingBudget.name}</p>
            </FlexBox>
            <p>{pendingBudget.value}</p>
          </FlexContainer>
        </>
      ) : null}
      <FlexContainer>
        <FlexBox>
          <ColorLegend $variant={BudgetPartName.REMAINING} />
          <p>{remainingBudget.name}</p>
        </FlexBox>
        <p style={{ marginLeft: '5rem' }}>{remainingBudget.value}</p>
      </FlexContainer>
    </DetailsWrapper>
  )

  return noBudgetLeft ? (
    <Card>
      <NoBudgetTitle>{noBudgetTitle}</NoBudgetTitle>
      <NoBudgetDescription>{noBudgetDescription}</NoBudgetDescription>
    </Card>
  ) : (
    <Card>
      <DetailsWrapper>
        <FlexContainer>
          <p>{remainingBudget.label}</p>
          <p>{remainingBudget.value}</p>
        </FlexContainer>
      </DetailsWrapper>

      <Tooltip
        content={tooltipContent}
        placement="bottom"
        lightBg
        shadowSize="medium"
      >
        <div>
          <ProgressBar
            progressPercentage={[usedPercentage, pendingPercentage]}
            backgroundColorComplete={['primary400', 'primary600']}
            backgroundColorIncomplete={['primary400', 'primary600']}
            shadeColor="gray90"
            sizeVariant="small"
            data-testid="budget-progress"
          />
        </div>
      </Tooltip>

      <Info>{resetInfo}</Info>
    </Card>
  )
}
