import { useCallback, useMemo, useReducer } from 'react'

import { MatchRequestFormContext } from '../matchRequestFormContext/MatchRequestFormContext'

import { MatchRequestFormContextControllerProps } from './MatchRequestFormContextController.types'
import { Organisation } from '@percent/workplace-giving/api/featured/featured.types'

const DEFAULT_STATE = {
  isOpen: false,
  isSuccess: undefined,
  defaultOrg: undefined
}

export type MatchRequestFormState = {
  isOpen: boolean
  isSuccess?: boolean
  defaultOrg?: Organisation
}

export type MatchRequestFormAction =
  | {
      type: 'OPEN'
      params?: {
        defaultOrg?: Organisation
      }
    }
  | {
      type: 'SUCCESS'
    }
  | {
      type: 'CLOSE'
    }

function reducer(state: MatchRequestFormState, action: MatchRequestFormAction): MatchRequestFormState {
  switch (action.type) {
    case 'OPEN': {
      return {
        isOpen: true,
        defaultOrg: action.params?.defaultOrg
      }
    }
    case 'SUCCESS': {
      return {
        ...state,
        isSuccess: true,
        defaultOrg: undefined
      }
    }
    case 'CLOSE': {
      return {
        isOpen: false,
        isSuccess: undefined,
        defaultOrg: undefined
      }
    }
    default: {
      return state
    }
  }
}

export function MatchRequestFormContextController({
  children,
  defaultState = DEFAULT_STATE
}: MatchRequestFormContextControllerProps) {
  const [state, d] = useReducer(reducer, defaultState)

  const dispatch = useCallback((action: MatchRequestFormAction) => {
    d(action)
  }, [])

  const value = useMemo(
    () => ({
      state,
      dispatch
    }),
    [state, dispatch]
  )

  return <MatchRequestFormContext.Provider value={value}>{children}</MatchRequestFormContext.Provider>
}
