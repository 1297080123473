import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { Button, Modal } from '@percent/lemonade'
import { SuccessIcon } from '@percent/workplace-giving/common/components/SuccessIcon/SuccessIcon'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { ModalProps } from 'libs/shared/ui-lemonade/src/components/modal'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type LogVolunteeringActivitySuccessModalProps = Pick<ModalProps, 'open'> & {
  onClose: VoidFunction
  isEditMode?: boolean
}

export function LogVolunteeringActivitySuccessModal({
  open,
  onClose,
  isEditMode
}: Readonly<LogVolunteeringActivitySuccessModalProps>) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { theme } = useColorTheme()

  return (
    <Modal onClose={onClose} open={open}>
      <Box padding={6} display="flex" flexDirection="column" alignItems="center">
        <SuccessIcon />
        <Typography my={2} textAlign="center" fontSize={24} fontWeight={600}>
          {t(`workplace_giving.volunteering.${isEditMode ? 'editActivityForm' : 'activityForm'}.success.modal.title`)}
        </Typography>
        <Typography mb={4} textAlign="center" fontSize={14} color={theme.neutral300}>
          {t(
            isEditMode
              ? 'workplace_giving.volunteering.editActivityForm.success.modal.description'
              : 'workplace_giving.volunteering.activityForm.success.modal.description'
          )}
        </Typography>
        <Button
          variant="primary"
          onPress={() => {
            onClose?.()
            navigate(AppRoute.MY_IMPACT, { state: { activeTab: 'volunteer' } })
          }}
        >
          {t('workplace_giving.common.done')}
        </Button>
      </Box>
    </Modal>
  )
}
