import { Money } from '@percent/utility'

export enum CardType {
  FUNDRAISER = 'fundraiser',
  OPPORTUNITY = 'opportunity',
  SUGGESTED_FUNDRAISER = 'suggested-fundraiser',
}

export type BasicFeaturedCardProps = {
  title: string
  badgeText?: string
  imgSrc?: string
  actionButton?: () => void
  cardButtonInfo?: React.ReactNode
}

export type FeaturedFundraiserCardProps = BasicFeaturedCardProps & {
  type: CardType.FUNDRAISER
  organisationName: string
  organisationLogo: string
  total: Money
  goal: Money
  locale: string
}

export type FeaturedOpportunityCardProps = BasicFeaturedCardProps & {
  type: CardType.OPPORTUNITY
  startDate?: string
  organisationName?: string
  organisationLogo?: string
  locationType: 'virtual' | 'in-person'
  locationText?: string
}

export type SuggestedFundraiserCardProps = BasicFeaturedCardProps & {
  type: CardType.SUGGESTED_FUNDRAISER
  organisationName: string
  organisationLogo: string
  total: Money
  goal: Money
  locale: string
}

export type FeaturedCardProps =
  | FeaturedFundraiserCardProps
  | FeaturedOpportunityCardProps
  | SuggestedFundraiserCardProps

export type TitleContentProps = {
  title: string
  isSuggestedFundraiser: boolean
  isTitleOverflowing: boolean
}
