import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { SuggestedRow } from '../SuggestedRow/SuggestedRow'
import { SCREEN_WIDTH_BREAKPOINT } from '../SuggestedOpportunitiesRow/useSuggestedOpportunitiesRow'

import { useQueryList } from '@percent/workplace-giving/common/hooks/useQueryList/useQueryList'
import { getSuggestedFundraisers } from '@percent/workplace-giving/api/fundraiser/suggestedFundraisers/suggestedFundraisers'
import {
  getAccountConfigFromAuthState,
  getCountryCodeFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth, useLogger, useWindowDimensions } from '@percent/workplace-giving/common/hooks'
import { FeaturedCard, FundraiserCardSkeleton } from '@percent/domain/giving'
import { CardType } from 'libs/domain/giving/src/components/featured-card/featured-card.types'

export function SuggestedFundraisersRow() {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const { state } = useAuth()
  const partner = getPartnerFromAuthState(state)!
  const accountConfig = getAccountConfigFromAuthState(state)
  const defaultCountry = getCountryCodeFromAuthState(state)!
  const { width: windowWidth } = useWindowDimensions()
  const { logError } = useLogger()
  const navigate = useNavigate()

  const isWindowWide = windowWidth > SCREEN_WIDTH_BREAKPOINT
  const tilesAmount = isWindowWide ? 4 : 3

  const currencyCode = accountConfig?.currency ?? partner.currencyCode

  const [params, setParams] = useState({
    pageSize: 4,
    currency: currencyCode,
    country: defaultCountry
  })

  const { data, isLoading, error } = useQueryList('suggestedFundraisers', params, getSuggestedFundraisers, setParams)

  const sectionTitle = t('workplace_giving.fundraising.suggestedFundraisers.title')

  if (isLoading) {
    return (
      <SuggestedRow
        title={sectionTitle}
        testId="suggested-fundraisers-row-skeleton"
        items={Array.from({ length: tilesAmount }, (_, i) => i.toString())}
        keyExtractor={key => key}
        renderItem={() => <FundraiserCardSkeleton />}
      />
    )
  }

  if (data?.data.length === 0) {
    return null
  }

  if (error) {
    logError(error)
  }

  return (
    <SuggestedRow
      title={sectionTitle}
      testId="suggested-fundraisers-row"
      items={data?.data?.slice(0, tilesAmount) || []}
      keyExtractor={f => f.id}
      renderItem={f => (
        <FeaturedCard
          type={CardType.SUGGESTED_FUNDRAISER}
          actionButton={() => {
            navigate(`/fundraisers/${f.id}`)
          }}
          title={f.title}
          organisationName={f.organisation.name}
          organisationLogo={f.organisation?.imageUrl}
          imgSrc={f.imageUrl}
          locale={locale}
          goal={f.goal}
          total={f.total}
        />
      )}
    />
  )
}
