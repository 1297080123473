import { Box, Divider, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { feature, permission, scope } from '../../hoc/withPermissions'
import { usePermission } from '../../hooks/usePermission/usePermission'
import { useAnalytics } from '../../hooks/useAnalytics/useAnalytics'

import { ButtonText, Tooltip } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useMatchingBudget, useMatchRequestForm } from '@percent/workplace-giving/common/hooks'
import { GamificationSection } from './GamificationSection/GamificationSection'
import { Permissions } from '@percent/workplace-giving/constants/permissions'
import classes from './SidePanelFooter.module.scss'

export function SidePanelFooter() {
  const { track } = useAnalytics()
  const { dispatch } = useMatchRequestForm()
  const navigation = useNavigate()
  const { t } = useTranslation()
  const has = usePermission()
  const { data: matching } = useMatchingBudget()

  const volunteeringEnabled = has(scope('volunteering'))
  const createFundraiserEnabled = has(scope('donation_link'), permission(Permissions.FundraiserWrite))
  const gamificationEnabled = has(scope('gamification'), feature('gamification'))
  const requestMatchVisible = has(scope('donation_matching')) && !!matching?.remainingAmount
  const requestMatchEnabled = (matching?.remainingAmount?.amount ?? 0) > 0

  return (
    <>
      {gamificationEnabled ? <GamificationSection /> : null}
      <Box className={classes.Wrapper}>
        <Box>
          <Text className={classes.SectionName}>{t('workplace_giving.navigation.donate')}</Text>
          <Box className={classes.ActionsWrapper}>
            {createFundraiserEnabled ? (
              <ButtonText
                onPress={() => {
                  track({
                    event: 'Donation Fundraiser Create Start',
                    properties: {
                      origin: 'Sidebar'
                    }
                  })
                  navigation(AppRoute.FUNDRAISER_WIZARD)
                }}
                variant="primary"
              >
                {t('workplace_giving.navigation.createFundraiser')}
              </ButtonText>
            ) : null}
            {requestMatchVisible &&
              (requestMatchEnabled ? (
                <ButtonText
                  onPress={() => {
                    dispatch({ type: 'OPEN' })
                    track({
                      event: 'Donation Match Request Start',
                      properties: {
                        origin: 'Sidebar'
                      }
                    })
                  }}
                  variant="primary"
                >
                  {t('workplace_giving.navigation.submitReceipt')}
                </ButtonText>
              ) : (
                <Tooltip content={t('workplace_giving.donationMatchRequest.noBudgetRemaining')}>
                  <Box className={classes.NoLink}>{t('workplace_giving.navigation.submitReceipt')}</Box>
                </Tooltip>
              ))}
          </Box>
        </Box>

        {volunteeringEnabled ? (
          <>
            <Divider className={classes.Divider} />
            <Box>
              <Text className={classes.SectionName}>{t('workplace_giving.navigation.volunteer')}</Text>
              <Box className={classes.ActionsWrapper}>
                <ButtonText
                  onPress={() => {
                    track({
                      event: 'Volunteering Opportunity Create Start',
                      properties: {
                        origin: 'Sidebar'
                      }
                    })
                    navigation(AppRoute.CREATE_OPPORTUNITY)
                  }}
                  variant="primary"
                >
                  {t('workplace_giving.navigation.createOpportunity')}
                </ButtonText>
                <ButtonText
                  onPress={() => {
                    track({
                      event: 'Volunteering Time Logging Start',
                      properties: {
                        origin: 'Sidebar'
                      }
                    })
                    navigation(AppRoute.LOG_VOLUNTEERING_ACTIVITY)
                  }}
                  variant="primary"
                >
                  {t('workplace_giving.navigation.logTime')}
                </ButtonText>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </>
  )
}
