import { AxiosInstance } from 'axios'

import { toGsResponse } from '../../goodstack'

import { GetPartnerThemeResponse } from './getPartnerTheme.types'

export const getPartnerTheme = async (client: AxiosInstance): Promise<GetPartnerThemeResponse | undefined> =>
  client
    .get<GetPartnerThemeResponse>('/partner/giving/config/theme', {
      withCredentials: true,
      validateStatus: (code: number) => code === 200 || code === 404
    })
    .then(r => toGsResponse(r.status, r.data))
    .then(r => (r.result === 'success' ? r : undefined))
    .catch(() => undefined)
