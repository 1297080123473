import { Box } from '@mantine/core'
import type { PropsWithChildren } from 'react'
import { clsx } from '@mantine/styles'
import type { To } from 'react-router-dom-v6'

import { SeeAllBtn } from '../SeeAllBtn/SeeAllBtn'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { Text, Icon } from '@percent/lemonade'
import type { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import classes from './DashboardCard.module.scss'

export type DashboardCardProps = Readonly<{
  icon?: IconProps['name']
  title?: string
  seeAllHref?: To
  gradient?: boolean
  testId?: string
  fullHeight?: boolean
}>

function DashboardIcon({ icon }: Readonly<{ icon: IconProps['name'] }>): JSX.Element {
  return (
    <Box className={classes.TitleIconWrapper}>
      <Icon name={icon} size={5} color="inherit" />
    </Box>
  )
}

function DashboardTitle({ title }: Readonly<{ title: string }>): JSX.Element {
  const { cssVariables } = useColorTheme()

  return (
    <Text size="large" fontWeight="semiBold" color={cssVariables['--black']}>
      {title}
    </Text>
  )
}

export function DashboardTitleRow({
  icon,
  title,
  seeAllHref
}: Readonly<Pick<DashboardCardProps, 'icon' | 'title' | 'seeAllHref'>>): JSX.Element {
  return (
    <Box className={classes.TitleRow}>
      <Box className={classes.TitleWrapper}>
        {icon && <DashboardIcon icon={icon} />}
        {title && <DashboardTitle title={title} />}
      </Box>
      {seeAllHref && (
        <Box className={classes.SeeMoreBtnWrapper}>
          <SeeAllBtn href={seeAllHref} />
        </Box>
      )}
    </Box>
  )
}

export function DashboardCard({
  icon,
  title,
  seeAllHref,
  gradient = false,
  children,
  testId,
  fullHeight
}: PropsWithChildren<DashboardCardProps>): JSX.Element {
  const showTitleRow = !!icon || !!title || !!seeAllHref

  return (
    <Box
      className={clsx(classes.DashboardCard, {
        [classes.GradientBackground]: gradient,
        [classes.FullHeight]: fullHeight
      })}
      data-testid={testId}
    >
      <Box style={{ height: '100%' }}>
        {showTitleRow && <DashboardTitleRow icon={icon} title={title} seeAllHref={seeAllHref} />}
        {children}
      </Box>
    </Box>
  )
}
