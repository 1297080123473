import { GivingCampaignOverviewDto } from '../campaign.types'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export const getCampaigns: QueryFn<[string], GivingCampaignOverviewDto[] | undefined> = async client => {
  const { data } = await client.get<{ data: GivingCampaignOverviewDto[] }>('/account/giving-campaigns', {
    withCredentials: true
  })

  return data.data
}
