import { Box, Skeleton, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { sanitize } from 'isomorphic-dompurify'
import classNames from 'classnames'

import { GamificationBadges } from '../../GamificationBadges/GamificationBadges'

import { useQuery } from '@percent/workplace-giving/common/hooks'
import classes from './GamificationSection.module.scss'
import { getGamificationDisplayConfig } from '@percent/workplace-giving/api/gamification/getGamificationDisplayConfig'

export function GamificationSection() {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(['getGamificationDisplayConfig'], getGamificationDisplayConfig, {
    staleTime: 1000 * 60
  })

  if (isLoading) {
    return (
      <Skeleton
        data-testid="gamification-section-loader"
        width="100%"
        height="100px"
        style={{ borderRadius: '0.25rem' }}
      />
    )
  }

  return (
    <Box className={classes.Wrapper} style={{ marginBottom: '1rem' }}>
      <Box>
        <Text className={classes.Title}>{data?.title ?? t('workplace_giving.sidePanel.achievements')}</Text>
        <div className={classNames({ [classes.ExtraPadding]: !data?.body })}>
          <GamificationBadges />
        </div>
        {data?.body && (
          <Text className={classes.Description}>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitize(data.body, { ALLOWED_TAGS: ['p', 'a'] })
              }}
            />
          </Text>
        )}
      </Box>
    </Box>
  )
}
