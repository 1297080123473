import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom-v6'

import { IllustratedMessage, Loader } from '@percent/lemonade'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { Description } from '@percent/workplace-giving/common/components'
import { getCampaignDetails } from '@percent/workplace-giving/api/campaigns/getCampaignDetails/getCampaignDetails'
import { CampaignDetailsSidebar } from './CampaignDetailsSidebar/CampaignDetailsSidebar'
import { CampaignFundraisers } from './CampaignFundraisers/CampaignFundraisers'
import { CampaignOpportunities } from './CampaignOpportunities/CampaignOpportunities'
import { CampaignOrganisations } from './CampaignOrganisations/CampaignOrganisations'
import classes from './Campaign.module.scss'

export function Campaign() {
  const { t } = useTranslation()
  const { campaignId } = useParams()
  const { logError } = useLogger()
  const navigate = useNavigate()

  const { data, isLoading, error } = useQuery(['getCampaignDetails', campaignId!], getCampaignDetails, {
    enabled: !!campaignId
  })

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    logError(error)

    return (
      <Page testId="campaign-details-page-wrapper-error">
        <Box style={{ width: '100%', textAlign: 'center', paddingTop: '2rem' }}>
          <IllustratedMessage
            illustration="no-results"
            title={t('workplace_giving.errors.pageUnavailable')}
            description={t('workplace_giving.errors.invalidPageUrl')}
            cta={{
              label: t('workplace_giving.common.returnHome'),
              onPress: () => navigate(AppRoute.HOME)
            }}
          />
        </Box>
      </Page>
    )
  }

  return (
    <Page testId="campaign-details-page-wrapper">
      {data && campaignId ? (
        <>
          <PageContent>
            <Description image={data.coverImage} name={data.title} story={data.description} />
            <Box className={classes.ActionSectionsWrapper} id="actions-section">
              <CampaignFundraisers campaignId={campaignId} />
              <CampaignOpportunities campaignId={campaignId} />
              <CampaignOrganisations campaignId={campaignId} />
            </Box>
          </PageContent>
          <CampaignDetailsSidebar campaign={data} />
        </>
      ) : null}
    </Page>
  )
}
