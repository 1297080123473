import { Box, Skeleton } from '@mantine/core'

import { UPCOMING_COUNT } from '../Upcoming'

export function SkeletonLoader() {
  return (
    <Box style={{ width: '100%' }} data-testid="upcomingSkeletonLoader">
      {Array.from({ length: UPCOMING_COUNT }).map((_, idx) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`upcoming-skeleton-item-${idx}`}
          style={{ display: 'flex', flexFlow: 'row', gap: '0.25rem', padding: '5px 0' }}
        >
          <Skeleton height={88} display="inline-block" width="20%" />
          <Skeleton height={88} display="inline-block" width="80%" />
        </Box>
      ))}
    </Box>
  )
}
