export const checkIfTextOverflows = (containerWidth: number, text: string, fontSize: number) => {
  const testTag = document.createElement('div')
  testTag.style.position = 'absolute'
  testTag.style.left = '-99in'
  testTag.style.whiteSpace = 'nowrap'
  testTag.style.fontSize = `${fontSize}px`
  testTag.innerHTML = text

  document.body.appendChild(testTag)

  const isOverflowing = testTag.clientWidth > containerWidth
  document.body.removeChild(testTag)

  return isOverflowing
}
