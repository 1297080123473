import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { Alpha2Code } from 'i18n-iso-countries'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { FlagAvatar, SearchInput, Select } from '@percent/lemonade'
import countries from '@percent/workplace-giving/i18n/countries'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { getShortLanguage } from '@percent/workplace-giving/utils/getShortLanguage'
import { ORGANISATION_SEARCH_MAX_LENGTH } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { getStyles } from './SearchBar.styles'

export type OrganisationSearchBarProps = Readonly<{
  query?: string
  onEmptyQuery?: () => void
  onQueryChange?: (query: string) => void
  countryCode: string
  onCountryChange?: (countryCode: string) => void
  onSearch?: (search: { country: string; query: string }) => void
  scrolled?: boolean
  fullWidth?: boolean
  size?: 'small' | 'medium'
}>

export function OrganisationSearchBar({
  query,
  onEmptyQuery,
  onQueryChange,
  countryCode,
  onCountryChange,
  onSearch,
  scrolled = false,
  fullWidth = true,
  size = 'small'
}: OrganisationSearchBarProps) {
  const { t, i18n } = useTranslation()
  const { validateString } = useSharedValidationRules()
  const Styles = getStyles(fullWidth)

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, getShortLanguage(i18n)),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [i18n, t]
  )

  const countryData = useMemo((): typeof alpha3CountryCodes[number] => {
    const countryOption = alpha3CountryCodes.filter(a => a.value === countryCode)[0]

    if (countryOption) {
      return countryOption
    }

    return alpha3CountryCodes[0]
  }, [alpha3CountryCodes, countryCode])

  const { errors, values, setFieldValue, handleChange, submitForm } = useFormik({
    initialValues: {
      query: query ?? ''
    },
    validateOnChange: true,
    validationSchema: () =>
      Yup.object().shape({
        query: validateString({ optional: true, maxLength: ORGANISATION_SEARCH_MAX_LENGTH })
      }),
    onSubmit: ({ query: _query }) => {
      const trimmed = _query.trim()

      if (trimmed !== '') {
        if (onQueryChange) {
          onQueryChange(trimmed)
        }

        if (onSearch) {
          onSearch({ country: countryCode, query: trimmed })
        }
      }
    }
  })

  useEffect(() => {
    if (!query || query === '') {
      setFieldValue('query', '')

      if (onEmptyQuery) {
        onEmptyQuery()
      }
    }
  }, [query, onEmptyQuery, setFieldValue])

  return (
    <Box sx={Styles.Wrapper}>
      <Box sx={Styles.Input}>
        <SearchInput
          name="query"
          handleClearValue={() => setFieldValue('query', '')}
          placeholder={t('workplace_giving.home.causeSearchPlaceholder')}
          value={values.query}
          onChange={handleChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submitForm()
            }
          }}
          aria-label="search"
          status={errors.query ? 'danger' : 'default'}
          statusMessage={errors.query}
          inputSize={size}
        />
      </Box>
      <Box sx={Styles.Select(scrolled)}>
        <Select
          placeholder="Select country"
          searchable
          onChange={event => {
            if (onCountryChange) {
              onCountryChange(event.value)
            }

            if (onSearch) {
              onSearch({ country: event.value, query: values.query })
            }
          }}
          data-testid="country"
          defaultValue={countryData}
          options={alpha3CountryCodes}
          showPrefixForSelectedOption
          size={size}
        />
      </Box>
    </Box>
  )
}
