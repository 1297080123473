import { Skeleton, Text, Box } from '@mantine/core'

import classes from './DashboardMetricCard.module.scss'
import { DashboardCard } from '@percent/workplace-giving/app/Home/DashboardCard/DashboardCard'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'

type DashboardMetricCardProps = {
  title: string
  value: string | number
  isLoading: boolean
  icon: IconProps['name']
  testId: string
}

export function DashboardMetricCard({ title, value, isLoading, icon, testId }: DashboardMetricCardProps): JSX.Element {
  return (
    <DashboardCard icon={icon} testId={testId} gradient>
      <Box className={classes.ContentWrapper}>
        <Text className={classes.Text}>{title}</Text>
        {isLoading ? (
          <Skeleton data-testid={`${testId}-skeleton`} height="2rem" radius="xl" width="120px" />
        ) : (
          <Text className={classes.TextLarge}>{value}</Text>
        )}
      </Box>
    </DashboardCard>
  )
}
