import { Box, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'

import classes from './TooltipTaxInformation.module.scss'
import { Icon, Tooltip } from '@percent/lemonade'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { getAccountCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { TooltipTaxInformationProps } from './TooltipTaxInformation.types'

export function TooltipTaxInformation({
  orgCountryCode,
  showIconOnly,
  placement = 'right'
}: TooltipTaxInformationProps) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const country = getAccountCountryCodeFromAuthState(state)

  return (
    country && (
      <Tooltip
        content={
          ['AUS', 'GBR', 'USA', 'IND'].includes(country) && country === orgCountryCode
            ? t('workplace_giving.tooltip.taxDeductible')
            : t('workplace_giving.tooltip.notTaxDeductible')
        }
        placement={isMobile ? 'top' : placement}
      >
        <Box className={classes.Wrapper} data-testid="tooltip-tax-information">
          <Icon name="info" size="m" color="neutral300" />
          {!showIconOnly && (
            <Text className={classes.TaxInformationText}>{t('workplace_giving.tooltip.taxInformation')}</Text>
          )}
        </Box>
      </Tooltip>
    )
  )
}
