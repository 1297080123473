import { Box, Text } from '@mantine/core'

import { SeeAllBtn } from '../SeeAllBtn/SeeAllBtn'

import classes from './SuggestedRow.module.scss'

export type SuggestedRowProps<T> = Readonly<{
  title: string
  seeAllHref?: string
  items: T[]
  keyExtractor: (item: T) => string
  renderItem: (item: T) => JSX.Element
  testId?: string
}>

export function SuggestedRow<T>({
  title,
  seeAllHref,
  items,
  keyExtractor,
  renderItem,
  testId
}: SuggestedRowProps<T>): JSX.Element {
  return (
    <Box className={classes.SuggestedRowContainer} data-testid={testId}>
      <Box className={classes.SuggestedRowHeader}>
        <Text component="h2" className={classes.SuggestedRowTitle}>
          {title}
        </Text>
        {seeAllHref && <SeeAllBtn href={seeAllHref} />}
      </Box>
      <Box className={classes.SuggestedRowContent}>
        {items.map(item => (
          <Box key={keyExtractor(item)} className={classes.SuggestedRowItem}>
            {renderItem(item)}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
