import { ListResponse } from '../../goodstack'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { LatestDonation } from './get-latest-donations.types.'

export const getLatestDonations: QueryFn<[string, { pageSize?: number }], ListResponse<LatestDonation>> = async (
  client,
  [_, { pageSize }]
) => {
  const res = await client.get<ListResponse<LatestDonation>>('/donations/latest', {
    withCredentials: true,
    params: { pageSize }
  })

  return res.data
}
