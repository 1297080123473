import { lazy, Suspense, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom-v6'
import * as Sentry from '@sentry/react'

import { SSOSignIn } from '../app/Auth/SSOSignIn/SSOSignIn'
import { EmailSignIn } from '../app/Auth/EmailSignIn/EmailSignIn'
import { SSOGateway } from '../app/Auth/SSOGateway/SSOGateway'
import { ForgotPassword } from '../app/Auth/ForgotPassword/ForgotPassword'
import { SignWithTwoFA } from '../app/Auth/TwoFactorAuthentication/SignInWithTwoFA/SignWithTwoFA'
import { scope, withAuth } from '../common/hoc/withPermissions'
import { getAccountFromAuthState } from '../context/auth/authContextController/AuthContextController'
import { useAnalytics } from '../common/hooks/useAnalytics/useAnalytics'
import { getPageTrackingName } from '../utils/getPageTrackingName/getPageTrackingName'
import { getQueryParamsObjWithoutToken } from '../utils/getQueryParamsObjWithoutToken'
import { NavBar } from '../common/components'
import { Home } from '../app/Home/Home'
import { EditVolunteeringActivity } from '../app/Volunteer/LogVolunteeringActivity/Edit/EditVolunteeringActivity'
import { SearchPage } from '../app/Search/SearchPage/SearchPage'
import { Campaign } from '../app/Campaign/Campaign'

import { AllowAuthenticatedAccountOnlyRoute } from './AllowAuthenticatedAccountOnlyRoute'
import { AppRoute } from './AppRoute.enum'
import { AllowUnauthenticatedAccountOnlyRoute } from './AllowUnauthenticatedAccountOnlyRoute'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { HandleNotFoundRedirection } from './HandleNotFoundRedirection'
import { Loader } from '@percent/lemonade'

const Donate = lazy(() => import('../app/Donate/Donate').then(module => ({ default: module.Donate })))
const Fundraiser = lazy(() => import('../app/Fundraiser/Fundraiser').then(module => ({ default: module.Fundraiser })))
const CreateOpportunity = lazy(() =>
  import('../app/Volunteer/OpportunityForm/Create/CreateOpportunity').then(module => ({
    default: module.CreateOpportunity
  }))
)
const EditOpportunity = lazy(() =>
  import('../app/Volunteer/OpportunityForm/Edit/EditOpportunity').then(module => ({ default: module.EditOpportunity }))
)
const MyFundraisers = lazy(() =>
  import('../app/Fundraiser/MyFundraisers/MyFundraisers').then(module => ({ default: module.MyFundraisers }))
)
const MyProfile = lazy(() => import('../app/MyProfile/MyProfile').then(module => ({ default: module.MyProfile })))
const FundraiserWizard = lazy(() =>
  import('../app/Fundraiser/FundraiserWizard/FundraiserWizard').then(module => ({ default: module.FundraiserWizard }))
)
const EditFundraiserWizard = lazy(() =>
  import('../app/Fundraiser/FundraiserWizard/Edit/EditFundraiserWizard').then(module => ({
    default: module.EditFundraiserWizard
  }))
)
const LogVolunteeringActivity = lazy(() =>
  import('../app/Volunteer/LogVolunteeringActivity/LogVolunteeringActivity').then(module => ({
    default: module.LogVolunteeringActivity
  }))
)
const MyImpact = lazy(() => import('../app/MyImpact/MyImpact').then(module => ({ default: module.MyImpact })))
const Volunteer = lazy(() => import('../app/Volunteer/Volunteer').then(module => ({ default: module.Volunteer })))
const MyOpportunities = lazy(() =>
  import('../app/MyOpportunities/MyOpportunities').then(module => ({ default: module.MyOpportunities }))
)
const OpportunityDetails = lazy(() =>
  import('../app/Volunteer/OpportunityDetails/OpportunityDetails').then(module => ({
    default: module.OpportunityDetails
  }))
)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function NavigateHome() {
  return <Navigate to={AppRoute.HOME} />
}

const AllowFundraising = withAuth(scope('donation_link'))(Outlet, NavigateHome)
const AllowVolunteering = withAuth(scope('volunteering'))(Outlet, NavigateHome)
const AllowCreateFundraising = withAuth(scope('donation_link'))(Outlet, NavigateHome)

export function AppRoutes() {
  const { state } = useAuth()
  const user = getAccountFromAuthState(state)
  const { page } = useAnalytics()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const authorizedPageName = getPageTrackingName(pathname)

    if (user?.id && authorizedPageName) {
      page({
        name: authorizedPageName,
        category: 'WPG',
        properties: {
          ...getQueryParamsObjWithoutToken(searchParams)
        }
      })
    }
  }, [pathname, searchParams, page, user?.id])

  return (
    <Suspense
      fallback={
        <>
          <NavBar />
          <Loader />
        </>
      }
    >
      <SentryRoutes>
        <Route element={<AllowUnauthenticatedAccountOnlyRoute />}>
          <Route path={AppRoute.SIGN_IN} element={<EmailSignIn />} />
          <Route path={AppRoute.OTP_VERIFY} element={<SignWithTwoFA />} />
          <Route path={AppRoute.SSO_SIGN_IN} element={<SSOSignIn />} />
          <Route path={AppRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />
        </Route>

        <Route element={<AllowAuthenticatedAccountOnlyRoute />}>
          <Route path={AppRoute.SSO_GATEWAY} element={<SSOGateway />} />
        </Route>

        <Route element={<AllowAuthenticatedAccountOnlyRoute />}>
          <Route path={AppRoute.HOME} element={<Home />} />
          <Route path={AppRoute.SEARCH} element={<SearchPage />} />
          <Route path={AppRoute.DONATE} element={<Donate />} />
          <Route path={AppRoute.MY_IMPACT} element={<MyImpact />} />
          <Route path={AppRoute.CAMPAIGN} element={<Campaign />} />

          <Route element={<AllowFundraising />}>
            <Route path={AppRoute.FUNDRAISER} element={<Fundraiser />} />
            <Route element={<AllowCreateFundraising />}>
              <Route
                path={AppRoute.MY_FUNDRAISER}
                element={
                  <MyProfile>
                    <MyFundraisers />
                  </MyProfile>
                }
              />
            </Route>
          </Route>

          <Route element={<AllowVolunteering />}>
            <Route path={AppRoute.VOLUNTEER} element={<Volunteer />} />
            <Route path={AppRoute.OPPORTUNITY_DETAILS} element={<OpportunityDetails />} />
            <Route
              path={AppRoute.MY_OPPORTUNITY}
              element={
                <MyProfile>
                  <MyOpportunities />
                </MyProfile>
              }
            />
          </Route>
        </Route>

        {/* without layout TODO: Change this to something more elegant */}
        <Route element={<AllowAuthenticatedAccountOnlyRoute withoutLayout />}>
          <Route element={<AllowVolunteering />}>
            <Route key="create-opportunity" path={AppRoute.CREATE_OPPORTUNITY} element={<CreateOpportunity />} />
            <Route key="edit-opportunity" path={AppRoute.EDIT_OPPORTUNITY} element={<EditOpportunity />} />
            <Route
              key="log-volunteering-activity"
              path={AppRoute.LOG_VOLUNTEERING_ACTIVITY}
              element={<LogVolunteeringActivity />}
            />
            <Route
              key="edit-volunteering-activity"
              path={AppRoute.EDIT_VOLUNTEERING_ACTIVITY}
              element={<EditVolunteeringActivity />}
            />
          </Route>

          <Route element={<AllowCreateFundraising />}>
            <Route path={AppRoute.FUNDRAISER_WIZARD} element={<FundraiserWizard />} />
            <Route key="edit-fundraiser" path={AppRoute.EDIT_FUNDRAISER} element={<EditFundraiserWizard />} />
          </Route>
        </Route>

        <Route path="*" element={<HandleNotFoundRedirection />} />
      </SentryRoutes>
    </Suspense>
  )
}
