import { Box, Typography } from '@mui/material'
import { NumberInput } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import type { FormikErrors, FormikTouched } from 'formik'
import type { FocusEvent } from 'react'

import { AsyncSelect, FormField } from '@percent/lemonade'
import { DatePicker } from '@percent/workplace-giving/common/components/DatePicker/DatePicker'
import { getStyles } from './LogVolunteeringActivityForm.styles'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import type { LogVolunteeringActivityFormFields } from './useLogVolunteeringActivityForm'
import { NoOpportunitiesFound } from './NoOpportunitiesFound'

export type LogOnPlatformActivityFormProps = Readonly<{
  registeredOpportunitiesCount?: number
  isFetchingRegisteredOpportunities: boolean
  registeredOpportunitiesQuery: string
  registeredOpportunitiesSearchResults: SelectOption[]
  setRegisteredOpportunitiesQuery: (query: string) => void
  values: LogVolunteeringActivityFormFields
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  touched: FormikTouched<LogVolunteeringActivityFormFields>
  errors: FormikErrors<LogVolunteeringActivityFormFields>
  handleBlur: <A = HTMLInputElement>(e: FocusEvent<A>) => void
  isEditMode?: boolean
}>

export function LogOnPlatformActivityForm({
  registeredOpportunitiesCount,
  registeredOpportunitiesSearchResults,
  setRegisteredOpportunitiesQuery,
  registeredOpportunitiesQuery,
  isFetchingRegisteredOpportunities,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  handleBlur,
  isEditMode
}: LogOnPlatformActivityFormProps) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  if (!registeredOpportunitiesCount && !isEditMode) {
    return <NoOpportunitiesFound />
  }

  return (
    <>
      <Box>
        <Typography sx={Styles.SectionTitle}>
          {t('workplace_giving.volunteering.activityForm.opportunityId.title')}
        </Typography>
        <FormField
          necessity="required"
          label={t('workplace_giving.volunteering.activityForm.opportunityId.label')}
          status={touched.opportunityId && errors.opportunityId ? 'danger' : 'default'}
          statusMessage={errors.opportunityId}
          data-testid="opportunitySearchField"
          disabled={isEditMode}
        >
          <AsyncSelect
            name="opportunityId"
            defaultValue={isEditMode ? registeredOpportunitiesSearchResults[0] : undefined}
            placeholder={t('workplace_giving.volunteering.activityForm.opportunityId.placeholder')}
            onChange={e => {
              setFieldValue('opportunityId', e?.value ?? '')
              setFieldTouched('opportunityId')
            }}
            options={registeredOpportunitiesSearchResults}
            setQuery={e => {
              setRegisteredOpportunitiesQuery(e)
              setFieldTouched('opportunityId')
              setFieldValue('opportunityId', e)
            }}
            query={registeredOpportunitiesQuery}
            loading={isFetchingRegisteredOpportunities}
            loadingText={t('workplace_giving.common.searching')}
            noResultsFoundText={
              registeredOpportunitiesQuery
                ? t('workplace_giving.volunteering.activityForm.opportunitySearch.noResults')
                : t('workplace_giving.volunteering.activityForm.opportunitySearch.emptyQuery')
            }
            data-testid="opportunitySearch"
          />
        </FormField>
      </Box>

      <Box>
        <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.activityForm.dateAndTime')}</Typography>
        <FormField
          label={t('workplace_giving.volunteering.activityForm.date.label')}
          necessity="required"
          status={touched.date && errors.date ? 'danger' : 'default'}
          statusMessage={errors.date}
          data-testid="dateField"
        >
          <DatePicker
            fieldName="date"
            placeholder={t('workplace_giving.volunteering.activityForm.date.placeholder')}
            value={values.date}
            handleBlur={handleBlur}
            handleChange={async date => {
              setFieldTouched('date')
              setFieldValue('date', date)
            }}
            error={!!errors.date && !!touched.date}
          />
        </FormField>

        <Box sx={Styles.FlexWrapper}>
          <FormField
            label={t('workplace_giving.volunteering.activityForm.hours.label')}
            necessity="required"
            status={touched.hours && errors.hours ? 'danger' : 'default'}
            statusMessage={errors.hours}
            data-testid="hoursField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="hours"
              min={0}
              max={24}
              step={1}
              hideControls
              allowNegative={false}
              allowDecimal={false}
              placeholder={t('workplace_giving.volunteering.activityForm.hours.placeholder')}
              value={values.hours}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('hours', value)
              }}
              error={!!errors.hours && !!touched.hours}
            />
          </FormField>

          <FormField
            label={t('workplace_giving.volunteering.activityForm.minutes.label')}
            necessity="required"
            status={touched.minutes && errors.minutes ? 'danger' : 'default'}
            statusMessage={errors.minutes}
            data-testid="minutesField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="minutes"
              min={0}
              max={59}
              step={1}
              hideControls
              allowNegative={false}
              allowDecimal={false}
              placeholder={t('workplace_giving.volunteering.activityForm.minutes.placeholder')}
              defaultValue={0}
              value={values.minutes}
              onBlur={e => {
                handleBlur(e)
                setFieldTouched('hours')
              }}
              onChange={value => {
                setFieldValue('minutes', value)
              }}
              error={!!errors.minutes && !!touched.minutes}
            />
          </FormField>
        </Box>
      </Box>
    </>
  )
}
