import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react'
import { useState, useEffect, ReactNode } from 'react'

import { useAuth } from '../common/hooks/useAuth/useAuth'
import {
  getAccountFromAuthState,
  getPartnerFromAuthState
} from '../context/auth/authContextController/AuthContextController'

import { environments } from '@percent/workplace-giving/constants/environments'
import { environment } from '@percent/workplace-giving/config/config'
import { getSplitKeyForUser } from '@percent/utility'

const getSplitKey = () => {
  const envMap: Record<string, string | undefined> = {
    [environments.PRODUCTION]: process.env.NX_SPLIT_PRODUCTION,
    [environments.SANDBOX]: process.env.NX_SPLIT_SANDBOX,
    [environments.PREPROD]: process.env.NX_SPLIT_PREPROD,
    [environments.STAGING]: process.env.NX_SPLIT_STAGING
  }

  // Returning 'localhost' allows testing locally with default feature values
  return envMap[environment as string] || process.env.NX_SPLIT_STAGING || 'localhost'
}

/*
  Split SDK Config should reflect whats on the split.io production feature flag values,
  the above splitSdkConfig features values are set as default values for feature flags
  when the split.io has timedout.
*/

export const features = {
  gamification: {
    key: 'wpg_gamification',
    defaultValue: 'off'
  },
  aiGenerateOpportunity: {
    key: 'wpg_ai_generate_opportunity',
    defaultValue: 'on'
  },
  paymentStatus: {
    key: 'wpg_payment_status',
    defaultValue: 'off'
  },
  campaigns: {
    key: 'wpg_campaigns_in_progress',
    defaultValue: 'off'
  },
  aiGenerateFundraiser: {
    key: 'wpg_ai_generate_fundraiser',
    defaultValue: 'on'
  }
}

export const featureNames = Object.keys(features) as (keyof typeof features)[]

export const featureKeys = Object.values(features).map(({ key }) => key)

export type Feature = typeof featureNames[0]
export const defaultFeatures = Object.fromEntries(featureNames.map(n => [n, false])) as Record<Feature, boolean>

export const splitSdkConfig = {
  core: {
    authorizationKey: getSplitKey(),
    key: 'key'
  },
  features: featureNames.reduce<Record<string, string>>((acc, v) => {
    acc[features[v].key] = features[v].defaultValue

    return acc
  }, {}),
  scheduler: {
    offlineRefreshRate: 15
  }
}

export function SplitSdkProvider({ children }: { children: ReactNode }) {
  const authState = useAuth()
  const user = getAccountFromAuthState(authState.state)
  const partner = getPartnerFromAuthState(authState.state)

  const [configKey, setConfigKey] = useState(user?.email ?? 'key')
  const [attributes, setAttributes] = useState<{ partner_id?: string }>({})

  useEffect(() => {
    if (user) {
      const key = getSplitKeyForUser(user)

      if (configKey !== key) {
        setConfigKey(key)
      }
    }

    if (partner) {
      if (attributes.partner_id !== partner.id) {
        setAttributes({ partner_id: partner.id })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, partner?.id])

  return (
    <SplitFactory config={splitSdkConfig}>
      <SplitClient splitKey={configKey} attributes={attributes} updateOnSdkTimedout>
        {children as any}
      </SplitClient>
    </SplitFactory>
  )
}
