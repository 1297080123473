import type { OpportunityActivityDto } from '../../opportunity/dto'
import type { RawValidResponse } from '../../goodstack'

import type { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export const getVolunteeringActivities: QueryFn<[string], OpportunityActivityDto[]> = async client => {
  const res = await client.get<RawValidResponse<OpportunityActivityDto[]>>(`/volunteering-activities`, {
    withCredentials: true
  })

  return res.data.data
}
