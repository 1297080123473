import { useTranslation } from 'react-i18next'

import { IllustratedMessage } from '@percent/lemonade'

export function NoOpportunitiesFound() {
  const { t } = useTranslation()

  return (
    <IllustratedMessage
      illustration="no-results"
      title={t('workplace_giving.volunteering.activityForm.onPlatform.noOpportunitiesFound.illustratedMessage.title')}
      description={t(
        'workplace_giving.volunteering.activityForm.onPlatform.noOpportunitiesFound.illustratedMessage.description'
      )}
    />
  )
}
