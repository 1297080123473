import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'
import { Box } from '@mantine/core'

import { DashboardTitleRow } from '../DashboardCard/DashboardCard'

import classes from './Upcoming.module.scss'
import { useAuth } from '@percent/workplace-giving/common/hooks/useAuth/useAuth'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import upcomingImg from '@percent/workplace-giving/assets/images/upcoming-calendar.png'
import starsImg from '@percent/workplace-giving/assets/images/stars.png'
import { ButtonText, Text } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { getAccountOpportunities } from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities'
import { useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader'
import { UpcomingItem } from './UpcomingItem/UpcomingItem'

export const UPCOMING_COUNT = 3

export function Upcoming({ removeTab = false }: { removeTab?: boolean }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logError } = useLogger()

  const authState = useAuth()
  const account = getAccountFromAuthState(authState.state)!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFirstLogin = useMemo(() => !account?.preferredName, [])

  const endDate = useMemo(() => new Date().toISOString(), [])

  const { data, isLoading, error } = useQuery(
    [
      'getAccountOpportunitiesCount',
      {
        pageSize: UPCOMING_COUNT,
        participationStatus: ['registered', 'confirmed'],
        endDateGt: endDate,
        direction: 'ASC',
        type: 'event'
      }
    ],
    getAccountOpportunities,
    { staleTime: 1000 * 60 }
  )

  if (error) {
    logError(error)

    return null
  }

  const noUpcoming = data?.totalResults ? data?.totalResults <= 0 : true

  return (
    <>
      {isLoading && <SkeletonLoader />}
      {isFirstLogin || data?.totalResults === 0 ? (
        <Box className={classes.Wrapper} data-testid="no-upcoming-wrapper">
          {removeTab && !isFirstLogin && !noUpcoming && (
            <DashboardTitleRow title={t('workplace_giving.upcoming.title')} seeAllHref={AppRoute.MY_OPPORTUNITY} />
          )}

          <Box className={classes.Banner}>
            <img src={starsImg} alt="" />
            <img src={upcomingImg} alt="" />
          </Box>

          {isFirstLogin ? (
            <>
              <Text className={classes.Title}>{t('workplace_giving.upcoming.startVolunteering.title')}</Text>
              <Text className={classes.Description}>
                {t('workplace_giving.upcoming.startVolunteering.description')}
              </Text>
            </>
          ) : (
            <>
              <Text className={classes.Title}>{t('workplace_giving.upcoming.keepVolunteering.title')}</Text>
              <Text className={classes.Description}>{t('workplace_giving.upcoming.keepVolunteering.description')}</Text>
            </>
          )}

          <Box className={classes.LinkWrapper}>
            <ButtonText onPress={() => navigate(AppRoute.VOLUNTEER)}>
              {t('workplace_giving.upcoming.exploreBtn')}
            </ButtonText>
          </Box>
        </Box>
      ) : null}

      {!isFirstLogin && !noUpcoming && (
        <Box className={classes.Wrapper}>
          {removeTab && (
            <DashboardTitleRow title={t('workplace_giving.upcoming.title')} seeAllHref={AppRoute.MY_OPPORTUNITY} />
          )}

          <Box data-testid="upcoming-list">
            {data?.data?.map(item => (
              <UpcomingItem key={`${item.registrationDate}-${item.opportunity.id}`} {...item} />
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}
