import { useEffect, useMemo } from 'react'

import { searchOpportunities } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities'
import type { SearchOpportunitiesResponseRow } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { useAuth, useInfiniteQuery, useLogger, useWindowDimensions } from '@percent/workplace-giving/common/hooks'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'

export type UseSuggestedOpportunitiesRow = Readonly<{
  opportunities: SearchOpportunitiesResponseRow[]
  isLoading: boolean
}>

export const SCREEN_WIDTH_BREAKPOINT = 1280

export const useSuggestedOpportunitiesRow = () => {
  const authState = useAuth()
  const { logError } = useLogger()
  const country = getCountryCodeFromAuthState(authState.state)
  const { width: windowWidth } = useWindowDimensions()

  const isWindowWide = windowWidth > SCREEN_WIDTH_BREAKPOINT
  const tilesAmount = isWindowWide ? 4 : 3

  const { data, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage, isError, error } = useInfiniteQuery(
    ['searchOpportunities', { country, pageSize: 25, orderBy: 'relevance' }],
    searchOpportunities,
    { staleTime: 1000 * 20 }
  )

  useEffect(() => {
    if (isError && error) {
      logError(error)
    }
  }, [isError, error, logError])

  const filteredData = useMemo(() => data?.pages.flatMap(page => page.data).filter((_, i) => i < 4) ?? [], [data])

  useEffect(() => {
    if (filteredData && filteredData?.length < 4 && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [filteredData, hasNextPage, isFetching, isFetchingNextPage, fetchNextPage])

  return {
    opportunities: filteredData.slice(0, tilesAmount),
    skeletonData: Array.from({ length: tilesAmount }, (_, i) => i.toString()),
    isLoading: isFetching || isFetchingNextPage
  }
}
