import { RawValidResponse } from '../../goodstack'
import { GivingCampaignDto } from '../campaign.types'

import type { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export const getCampaignDetails: QueryFn<[string, string], GivingCampaignDto> = async (client, [_, campaignId]) => {
  const res = await client.get<RawValidResponse<GivingCampaignDto>>(`/giving-campaign/${campaignId}`, {
    withCredentials: true
  })

  return res.data.data
}
