import { useState, ReactNode, useMemo, useEffect } from 'react'

import { ColorThemeContext } from '../colorThemeContext/ColorThemeContext'

import { colorPaletteToCssVariables } from '@percent/workplace-giving/utils/colorPaletteToCssVariables'

export const BASE_PALETTE = {
  primary100: '#F4EDFF',
  primary400: '#8B4AFF',
  primary600: '#412276',
  secondary100: '#e9fbf9',
  secondary400: '#23d3c1',
  secondary600: '#23c9b8',
  neutral100: '#e5effc',
  neutral300: '#6D6A73',
  neutral400: '#1b034f',
  neutral500: '#56525c',
  neutral600: '#110231',
  info30: '#FBF8FF',
  info100: '#F4EDFF',
  info400: '#8B4AFF',
  warning30: '#FEFBF8',
  warning100: '#FDE3CC',
  warning400: '#E77309',
  warningAlt100: '#FFF9E9',
  success30: '#F7FBFA',
  success100: '#DFF6EC',
  success400: '#008850',
  alert30: '#FEF8F8',
  alert100: '#FCE2E3',
  alert400: '#E71A22',
  white: '#ffffff',
  gray20: '#E1E2E3',
  gray70: '#F5F5F6',
  gray80: '#F1F0F2',
  gray90: '#E9E8EB',
  gray100: '#B6B4B9',
  gray200: '#070C18',
  gray300: '#6D6A73',
  gray400: '#615E67',
  gray500: '#56525C',
  gray600: '#494550',
  gray700: '#3D3944',
  gray800: '#404858',
  gray900: '#25202D',
  black: '#0D0D0D',
  primary: '#8B3DFF',
  primaryHover: '#412276',
  primaryActive: '#412276',
  primaryFocus: '#412276'
}

export type ColorPaletteType = typeof BASE_PALETTE

export function ColorThemeProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [theme, setTheme] = useState<ColorPaletteType>(BASE_PALETTE)

  const value = useMemo(() => {
    const cssVariables = colorPaletteToCssVariables(theme)

    return {
      theme,
      setTheme,
      cssVariables
    }
  }, [theme])

  useEffect(() => {
    const root = document.documentElement

    for (const [key, val] of Object.entries(value.cssVariables)) {
      root.style.setProperty(key, val)
    }
  }, [value.cssVariables])

  return <ColorThemeContext.Provider value={value}>{children}</ColorThemeContext.Provider>
}
