import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v6'

import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import countries from '@percent/workplace-giving/i18n/countries'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useAuth } from '@percent/workplace-giving/common/hooks'

export type UseOrganisationSearchBarReturn = Readonly<{
  countryCode: string
  onQueryChange: (query: string) => void
  onCountryChange: (country: string) => void
}>

export const useOrganisationSearchBar = (): UseOrganisationSearchBarReturn => {
  const authState = useAuth()
  const navigate = useNavigate()

  const [rawCountryCode, setRawCountryCode] = useState<string | undefined>(undefined)

  const countryCode = useMemo(() => {
    if (rawCountryCode === '' || (rawCountryCode && countries.isValid(rawCountryCode))) {
      return rawCountryCode
    }

    const codeFromAuthState = getCountryCodeFromAuthState(authState.state)

    return codeFromAuthState && countries.isValid(codeFromAuthState) ? codeFromAuthState : ''
  }, [authState.state, rawCountryCode])

  const onCountryChange = (country: string) => {
    setRawCountryCode(country)
  }

  const onQueryChange = (query: string) => {
    if (query.trim().length) {
      navigate({
        pathname: AppRoute.SEARCH,
        search: `?query=${query}&country=${countryCode}`
      })
    }
  }

  return { countryCode, onQueryChange, onCountryChange }
}
