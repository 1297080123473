import { Box, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom-v6'

import { Badge, ButtonText } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import classes from './CampaignCard.module.scss'
import { GivingCampaignOverviewDto } from '@percent/workplace-giving/api/campaigns/campaign.types'

export function CampaignCard({ id, title, coverImage, shortDescription }: GivingCampaignOverviewDto) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box className={classes.Wrapper} data-testid="campaign-card">
      <Link to={AppRoute.CAMPAIGN.replace(':campaignId', id)} className={classes.Link}>
        <Box className={classes.CoverImg} style={{ backgroundImage: `url(${coverImage})` }}>
          <Badge variant="informative">{t('workplace_giving.common.campaign')}</Badge>
        </Box>
        <Text className={classes.Title}>{title}</Text>
        <Text className={classes.Description}>{shortDescription}</Text>
        <Box className={classes.ActionsWrapper}>
          <ButtonText
            onPress={() => {
              navigate(AppRoute.CAMPAIGN.replace(':campaignId', id))
            }}
          >
            {t('workplace_giving.common.learnMore')}
          </ButtonText>
        </Box>
      </Link>
    </Box>
  )
}
