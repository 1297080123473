import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'
import { VolunteeringTimeLogType } from '../../opportunity/dto'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { EditVolunteeringActivityParams } from './editVolunteeringActivity.types'

export const editVolunteeringActivity: MutationFn<[EditVolunteeringActivityParams], GsResponse<any>> = async (
  client: Axios,
  [params]
) => {
  const { timeLogId, ...activityLogParams } = params
  const { platform, ...rest } = activityLogParams

  const requestData =
    platform === VolunteeringTimeLogType.OffPlatform
      ? {
          ...rest,
          ...(platform === VolunteeringTimeLogType.OffPlatform &&
            activityLogParams.activities && {
              activities: JSON.stringify(activityLogParams.activities)
            }),
          ...(platform === VolunteeringTimeLogType.OffPlatform &&
            activityLogParams.skills && {
              skills: JSON.stringify(activityLogParams.skills)
            })
        }
      : {
          ...rest
        }

  const response = await client.patch<RawResponse<any>>(`/account/volunteering-time-logs/${timeLogId}`, requestData, {
    withCredentials: true,
    validateStatus: (_: number) => true
  })

  return toGsResponse(response.status, response.data)
}
