export const ORGANISATION_SEARCH_MAX_LENGTH = 100

export type SearchOrganisationQuery = {
  query?: string
  pageSize?: number
  countryCode?: string
}

export type Organisation = {
  id: string
  name: string
  displayName?: string | null
  countryCode: string
  description?: string
  logo?: string | null
  website?: string
  registryId?: string | null
  address?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  state?: string | null
  postalCode?: string | null
}
