import { Box, Skeleton, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import classes from '../Campaign.module.scss'

import { useInfiniteQuery } from '@percent/workplace-giving/common/hooks'
import { OpportunityCard } from '@percent/workplace-giving/common/components/OpportunityCard/OpportunityCard'
import { getCampaignOpportunities } from '@percent/workplace-giving/api/campaigns/getCampaignOpportunities/getCampaignOpportunities'
import { OpportunityCardSkeleton } from '@percent/workplace-giving/common/components/OpportunityCard/OpportunityCardSkeleton'
import { mapInfiniteData } from '@percent/workplace-giving/utils/mapInfiniteData'

export function CampaignOpportunities({ campaignId }: Readonly<{ campaignId: string }>) {
  const { t } = useTranslation()
  const { data, isFetching } = useInfiniteQuery(
    ['getCampaignOpportunities', { campaignId, pageSize: 10 }],
    getCampaignOpportunities,
    { staleTime: 1000 * 20 }
  )

  const mappedData = mapInfiniteData(data)

  if (isFetching) {
    return (
      <Box className={classes.SectionWrapper} data-testid="campaign-opportunities-section-loader">
        <Skeleton height="2rem" width="55%" />
        <Box className={classes.SectionTilesContentWrapper}>
          <OpportunityCardSkeleton />
        </Box>
      </Box>
    )
  }

  if (mappedData) {
    return (
      <Box className={classes.SectionWrapper} data-testid="campaign-opportunities-section">
        <Text className={classes.SectionTitle}>{t('workplace_giving.campaigns.upcomingOpportunities')}</Text>
        <Box className={classes.SectionTilesContentWrapper}>
          {mappedData.pages.map(p => p.map(o => <OpportunityCard key={o.id} opportunity={o} />))}
        </Box>
      </Box>
    )
  }

  return null
}
