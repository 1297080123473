import { useEffect } from 'react'

import { MatchingBudget } from '@percent/workplace-giving/common/components/Donation/MatchingBudget/MatchingBudget'
import { TotalDonated } from '@percent/workplace-giving/common/components/Donation/TotalDonated/TotalDonated'
import {
  useMatchingBudget,
  useDonationWallet,
  useDonationSummary,
  useMatchRequestForm
} from '@percent/workplace-giving/common/hooks'
import { SidePanelSkeleton } from './SidePanelSkeleton'
import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { SidePanelFooter } from '@percent/workplace-giving/common/components/SidePanelFooter/SidePanelFooter'
import { WalletBalance } from '@percent/workplace-giving/common/components/WalletBalance/WalletBalance'
import classes from './SidePanel.module.scss'

export function SidePanel() {
  const {
    data: donationSummary,
    isFetching: donationSummaryLoading,
    refetch: refetchDonationSummary
  } = useDonationSummary()
  const {
    data: matching,
    isFetching: donationMatchingSummaryLoading,
    refetch: refetchDonationMatchingSummary
  } = useMatchingBudget()
  const {
    data: wallet,
    isFetching: donationWalletLoading,
    refetch: refetchDonationWallet,
    isError
  } = useDonationWallet()
  const {
    state: { isOpen, isSuccess }
  } = useMatchRequestForm()

  const isLoading =
    (!donationSummary && donationSummaryLoading) ||
    (!matching && donationMatchingSummaryLoading) ||
    (!wallet && donationWalletLoading)

  const refetchBudgetData = () => {
    refetchDonationMatchingSummary()
    refetchDonationSummary()
  }

  useEffect(() => {
    if (isOpen && isSuccess) {
      refetchDonationMatchingSummary()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isSuccess])

  if (isLoading) {
    return <SidePanelSkeleton />
  }

  return (
    <SidePanelContainer>
      {!donationWalletLoading && !isError && wallet && <WalletBalance balance={wallet.balance} />}
      <div className={classes.TotalDonationContainer}>
        {donationSummary && (
          <TotalDonated
            totals={donationSummary}
            refetchBudgetData={refetchBudgetData}
            refetchDonationWallet={refetchDonationWallet}
          />
        )}
        {matching?.usedAmount && matching?.remainingAmount && matching?.resetDate && matching?.totalAmount ? (
          <MatchingBudget
            remainingAmount={matching.remainingAmount}
            usedAmount={matching.usedAmount}
            pendingAmount={matching.pendingAmount}
            totalAmount={matching.totalAmount}
            resetDate={matching.resetDate}
          />
        ) : null}
      </div>
      <SidePanelFooter />
    </SidePanelContainer>
  )
}
