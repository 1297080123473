import { Box } from '@mantine/core'

import { Icon } from '@percent/lemonade'
import classes from './SuccessIcon.module.scss'

export function SuccessIcon() {
  return (
    <Box className={classes.IconWrapper}>
      <Icon name="check" size="xl" color="success400" />
    </Box>
  )
}
