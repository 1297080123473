import { useTranslation } from 'react-i18next'

import { Icon, Text } from '@percent/lemonade'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { formatShortOfflineOpportunityAddress } from '@percent/workplace-giving/utils/formatOfflineOpportunityAddress'
import { SearchOpportunitiesResponseRow } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { OpportunityLocationTypeDto, OpportunityType } from '@percent/workplace-giving/api/opportunity/dto'
import classes from './OpportunityCard.module.scss'

export function OpportunityCardContent({ startDate, location, timeTracking, type }: SearchOpportunitiesResponseRow) {
  const { t } = useTranslation()
  const { formatDate } = useDateTimeFmt()

  return (
    <div className={classes.DetailsWrapper}>
      {timeTracking === 'manual' || !startDate ? null : (
        <div data-testid="opportunity-card-date-info">
          <Icon name="calendar" size="m" color="neutral300" />
          <Text as="p" size="small" fontWeight="medium">
            {formatDate(new Date(startDate))}
          </Text>
        </div>
      )}
      {type === OpportunityType.EventSeries ? (
        <div>
          <Icon name="info" size="m" color="neutral300" />
          <Text as="p" size="small" fontWeight="medium">
            {t('workplace_giving.volunteering.multipleSessions')}
          </Text>
        </div>
      ) : (
        <div>
          <Icon
            name={location.type === OpportunityLocationTypeDto.Virtual ? 'virtual-meeting' : 'pin'}
            size="m"
            color="neutral300"
          />
          <Text as="p" size="small" fontWeight="medium">
            {location.type === OpportunityLocationTypeDto.Virtual
              ? t('workplace_giving.volunteering.virtualOpportunity')
              : formatShortOfflineOpportunityAddress({ location })}
          </Text>
        </div>
      )}
    </div>
  )
}
