import { Card, Box, Skeleton } from '@mantine/core'

import classes from './SearchResultCardSkeleton.module.scss'

export function SearchResultCardSkeleton({ supportedByColleaguesVariant }: { supportedByColleaguesVariant?: boolean }) {
  return (
    <Card className={classes.Card} data-testid="search-result-skeleton">
      <Box className={classes.CardContent}>
        <Box className={classes.TopWrapper}>
          <Box style={{ width: '70%' }}>
            <Box className={classes.FlexBox} style={{ marginBottom: '1rem' }}>
              <Skeleton variant="rectangular" width={180} height={24} />
              <Skeleton variant="rectangular" width={30} height={24} />
            </Box>
            <Box className={classes.FlexBox}>
              <Skeleton variant="rectangular" width="90%" height={25} />
            </Box>
          </Box>
          <Skeleton variant="rectangular" width={64} height={64} />
        </Box>

        <Skeleton variant="rectangular" width={92} height={32} style={{ borderRadius: 4 }} />

        {supportedByColleaguesVariant && (
          <Box className={classes.FlexBox} style={{ marginTop: '1rem' }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rectangular" width="60%" height={24} />
          </Box>
        )}
      </Box>
    </Card>
  )
}
