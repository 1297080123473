import { useTranslation } from 'react-i18next'

import { useMoneyFormat } from '@percent/workplace-giving/common/hooks'
import { useTotalDonatedCard } from './useTotalDonatedCard'
import { DashboardMetricCard } from '@percent/workplace-giving/common/components/DashboardMetricCard/DashboardMetricCard'

export function TotalDonatedCard(): JSX.Element {
  const { t } = useTranslation()
  const { isLoading, totalDonated } = useTotalDonatedCard()
  const { moneyFormat } = useMoneyFormat()

  return (
    <DashboardMetricCard
      title={t('workplace_giving.totalDonated.title')}
      value={moneyFormat(totalDonated)}
      isLoading={isLoading}
      icon="donate-outline"
      testId="total-donated-card"
    />
  )
}
