import { format, isToday, isTomorrow } from 'date-fns'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom-v6'

import { Text } from '@percent/lemonade'
import { OpportunitySelfParticipationDto } from '@percent/workplace-giving/api/opportunity/dto'
import classes from './UpcomingItem.module.scss'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks'

export function UpcomingItem({ opportunity }: OpportunitySelfParticipationDto) {
  const { t } = useTranslation()
  const { formatDurationDateTime } = useDateTimeFmt()

  const getDayName = (date: string) => {
    const formattedDate = new Date(date)

    if (isToday(formattedDate)) {
      return t('workplace_giving.upcoming.today')
    }

    if (isTomorrow(formattedDate)) {
      return t('workplace_giving.upcoming.tomorrow')
    }

    return format(formattedDate, 'EEEE')
  }

  return (
    <Box className={classes.Wrapper}>
      <Box>
        {opportunity.startDate ? (
          <Box className={classes.DateWrapper}>
            <Text size="small">{getDayName(opportunity.startDate)}</Text>
            <Text size="small">{format(new Date(opportunity.startDate), 'MMMM dd')}</Text>
          </Box>
        ) : undefined}
      </Box>
      <Box className={classes.ContentWrapper}>
        <Link to={`/volunteer/opportunities/${opportunity.id}`}>
          <Text size="small" fontWeight="semiBold">
            {opportunity.name}
          </Text>
          {opportunity.startDate ? (
            <Text size="small">
              {formatDurationDateTime(
                new Date(opportunity.startDate),
                opportunity.endDate ? new Date(opportunity.endDate) : undefined
              )}
            </Text>
          ) : undefined}
          <Text className={classes.Attendees} size="small">
            {t('workplace_giving.volunteering.upcoming.attendees', {
              count: opportunity.participantsCount
            })}
          </Text>
        </Link>
      </Box>
    </Box>
  )
}
