type BudgetPart = {
  name: string
  value: string
  label?: string
}

export enum BudgetPartName {
  PENDING = 'pendingBudget',
  USED = 'usedBudget',
  REMAINING = 'remainingBudget',
}

export type ColorLegendProps = {
  $variant: BudgetPartName
}

export type BudgetDetailsProps = {
  noBudgetLeft: boolean
  noBudgetTitle: string
  noBudgetDescription: string
  totalBudget: BudgetPart
  usedBudget: BudgetPart
  pendingBudget: BudgetPart
  remainingBudget: BudgetPart
  resetInfo: string
  isBudgetTouched: boolean
  usedPercentage: number
  pendingPercentage: number
}
