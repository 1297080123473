import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom-v6'

import { Tooltip, Text } from '@percent/lemonade'
import type { SearchOpportunitiesResponseRow } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import classes from './OpportunityCard.module.scss'
import { OpportunityCardContent } from './OpportunityCardContent'
import { checkIfTextOverflows } from '@percent/workplace-giving/utils/checkIfTextOverflows'

export type OpportunityCardProps = Readonly<{
  opportunity: SearchOpportunitiesResponseRow
  className?: string
  track?: VoidFunction
}>

export function OpportunityCard({ opportunity, className, track }: OpportunityCardProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false)

  useEffect(() => {
    if (containerRef.current) {
      setIsTitleOverflowing(checkIfTextOverflows(containerRef.current.clientWidth, opportunity.name, 18))
    }
  }, [opportunity.name])

  return (
    <div className={className} ref={containerRef}>
      <Link to={`/volunteer/opportunities/${opportunity.id}`} className={classes.Link} onClick={() => track?.()}>
        <div className={classes.Wrapper}>
          <div className={classes.Header} style={{ backgroundImage: `url(${opportunity.image})` }}>
            {opportunity.organisation?.logo && (
              <Tooltip content={opportunity.organisation.name} placement="top">
                <div
                  className={classes.Logo}
                  style={{ backgroundImage: `url(${opportunity.organisation.logo})` }}
                  data-testid="organisation-logo"
                />
              </Tooltip>
            )}
          </div>
          <div className={classes.ContentWrapper}>
            {isTitleOverflowing ? (
              <Tooltip content={opportunity.name} placement="top">
                <div className={classes.Title}>
                  <Text as="p" size="large" fontWeight="semiBold" overflowEllipsis>
                    {opportunity.name}
                  </Text>
                </div>
              </Tooltip>
            ) : (
              <div className={classes.Title}>
                <Text as="p" size="large" fontWeight="semiBold" overflowEllipsis>
                  {opportunity.name}
                </Text>
              </div>
            )}
            <OpportunityCardContent {...opportunity} />
          </div>
        </div>
      </Link>
    </div>
  )
}
