import { Box, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { CopyContentButton } from '@percent/domain/giving'
import { Alert, Button, Icon } from '@percent/lemonade'
import classes from './CampaignDetailsSidebar.module.scss'
import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { GivingCampaignDto } from '@percent/workplace-giving/api/campaigns/campaign.types'
import { dayjs } from '@percent/workplace-giving/utils/dayjs/dayjs'

type CampaignDetailsSidebarProps = {
  campaign: GivingCampaignDto
}

export function CampaignDetailsSidebar({ campaign }: Readonly<CampaignDetailsSidebarProps>) {
  const { t } = useTranslation()
  const { track } = useAnalytics()
  const { formatDateRange } = useDateTimeFmt()

  const { id, startDate, endDate } = campaign
  const campaignEnded = endDate ? dayjs(endDate) <= dayjs(new Date()) : false

  const scrollToActionsSections = () => {
    const sessionsSection = document.getElementById('actions-section')

    sessionsSection?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <SidePanelContainer>
      <Box data-testid="campaign-details-sidebar" className={classes.Wrapper}>
        {campaignEnded ? <Alert variant="info" title={t('workplace_giving.campaigns.alert.campaignEnded')} /> : null}
        <Box className={classes.DetailsContainer}>
          <Box>
            <Icon name="calendar" size="m" color="neutral300" />
            <Box>
              <Text>{formatDateRange(new Date(startDate), new Date(endDate))}</Text>
            </Box>
          </Box>

          <Box>
            <Icon name="earth" size="m" color="neutral300" />
            <Box>
              <Text>{t('workplace_giving.common.global')}</Text>
            </Box>
          </Box>
        </Box>

        <Box className={classes.DetailsContainer}>
          <Button stretch size="large" onPress={scrollToActionsSections}>
            {t('workplace_giving.campaigns.howCanIHelp')}
          </Button>

          <CopyContentButton
            stretch
            size="large"
            content={window.location.href}
            onPress={() => {
              track({
                event: 'Giving Campaign Link Sharing',
                properties: {
                  campaignId: id
                }
              })
            }}
          >
            {t('workplace_giving.common.copyShareLink')}
          </CopyContentButton>
        </Box>
      </Box>
    </SidePanelContainer>
  )
}
