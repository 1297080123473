import { useNavigate } from 'react-router-dom-v6'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { DashboardTitleRow } from '../DashboardCard/DashboardCard'

import classes from './LatestDonations.module.scss'
import noDonations from '@percent/workplace-giving/assets/images/no-donations.png'
import starsImg from '@percent/workplace-giving/assets/images/stars.png'
import { ButtonText, Text } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { LatestDonationsItem } from './LatestDonationsItem/LatestDonationsItem'
import { useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { getLatestDonations } from '@percent/workplace-giving/api/donation/latest-donations/get-latest-donations'
import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader'

export const LATEST_DONATIONS_COUNT = 20

export function LatestDonations({ removeTab = false }: { removeTab?: boolean }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { logError } = useLogger()

  const { data, isLoading, error } = useQuery(
    [
      'getLatestDonations',
      {
        pageSize: LATEST_DONATIONS_COUNT
      }
    ],
    getLatestDonations,
    {
      staleTime: 1000 * 60
    }
  )

  if (error) {
    logError(error)

    return null
  }

  return (
    <>
      {removeTab && data?.totalResults !== 0 && (
        <DashboardTitleRow title={t('workplace_giving.latestDonations.title')} />
      )}
      {isLoading && <SkeletonLoader />}
      {data?.totalResults === 0 && (
        <Box className={classes.Wrapper} data-testid="no-latest-donations-wrapper">
          <Box className={classes.Banner}>
            <img src={starsImg} alt="" />
            <img src={noDonations} alt="" />
          </Box>

          <Text className={classes.Title}>{t('workplace_giving.latestDonations.noDonationsYet')}</Text>
          <Text className={classes.Description}>{t('workplace_giving.latestDonations.description')}</Text>

          <Box className={classes.LinkWrapper}>
            <ButtonText onPress={() => navigate(AppRoute.DONATE)}>
              {t('workplace_giving.latestDonations.findANonprofit')}
            </ButtonText>
          </Box>
        </Box>
      )}
      {data?.totalResults !== 0 && (
        <Box className={classes.Wrapper} data-testid="latest-donations-wrapper">
          <Box>
            {data?.data?.map(item => (
              <LatestDonationsItem key={`${item.createdAt}-${item.amount.amount}-${item.organisation.id}`} {...item} />
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}
