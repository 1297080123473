import { FocusEvent, useEffect } from 'react'
import { RichTextEditor as MantineRichTextEditor, Link } from '@mantine/tiptap'
import { Content, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import { Box } from '@mantine/core'

import classes from './RichTextEditor.module.scss'

interface RichTextEditorProps {
  fieldName: string
  placeholder: string
  handleBlur: (e: FocusEvent<HTMLInputElement, Element>) => void
  handleUpdate: (value: string) => void
  error: boolean
  content?: Content
  bottomAddOn?: React.ReactNode
}

export const handleOnBlur =
  (handleBlur: (_: FocusEvent<HTMLInputElement, Element>) => void, fieldName: string) =>
  ({ event }: { event: globalThis.FocusEvent }) => {
    const blurEvent = event as unknown as FocusEvent<HTMLInputElement, Element>

    if (blurEvent?.target) {
      blurEvent.target.name = fieldName
    }
    handleBlur(blurEvent)
  }

export function RichTextEditor({
  fieldName,
  placeholder,
  handleUpdate,
  handleBlur,
  content,
  error,
  bottomAddOn
}: Readonly<RichTextEditorProps>) {
  const richTextEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        HTMLAttributes: {
          target: '_blank'
        }
      }),
      Highlight,
      Placeholder.configure({ placeholder })
    ],
    content,
    onUpdate: ({ editor }) => {
      handleUpdate(editor.getText().length ? editor.getHTML() : '')
    },
    onBlur: handleOnBlur(handleBlur, fieldName)
  })

  useEffect(() => {
    if (richTextEditor?.getHTML() !== content) {
      richTextEditor?.commands.setContent(content as Content)
    }

    if (!richTextEditor?.getText().length && content?.length) {
      richTextEditor?.commands.setContent(content as Content)
    }
  }, [content, richTextEditor])

  return (
    <>
      <Box className={classes.EditorStyles}>
        <MantineRichTextEditor
          styles={bottomAddOn ? { root: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } } : {}}
          editor={richTextEditor}
          aria-invalid={error}
          classNames={{
            root: classes.EditorRoot,
            content: classes.EditorContent
          }}
        >
          <MantineRichTextEditor.Toolbar sticky stickyOffset={80}>
            <MantineRichTextEditor.ControlsGroup>
              <MantineRichTextEditor.Bold />
              <MantineRichTextEditor.Italic />
              <MantineRichTextEditor.Underline />
              <MantineRichTextEditor.Strikethrough />
              <MantineRichTextEditor.ClearFormatting />
              <MantineRichTextEditor.Highlight />
            </MantineRichTextEditor.ControlsGroup>
            <MantineRichTextEditor.ControlsGroup>
              <MantineRichTextEditor.H1 />
              <MantineRichTextEditor.H2 />
              <MantineRichTextEditor.H3 />
            </MantineRichTextEditor.ControlsGroup>
            <MantineRichTextEditor.ControlsGroup>
              <MantineRichTextEditor.Blockquote />
              <MantineRichTextEditor.Hr />
              <MantineRichTextEditor.BulletList />
              <MantineRichTextEditor.OrderedList />
            </MantineRichTextEditor.ControlsGroup>
            <MantineRichTextEditor.ControlsGroup>
              <MantineRichTextEditor.Link />
              <MantineRichTextEditor.Unlink />
            </MantineRichTextEditor.ControlsGroup>
            <MantineRichTextEditor.ControlsGroup>
              <MantineRichTextEditor.Undo />
              <MantineRichTextEditor.Redo />
            </MantineRichTextEditor.ControlsGroup>
          </MantineRichTextEditor.Toolbar>
          <MantineRichTextEditor.Content />
        </MantineRichTextEditor>
      </Box>
      {bottomAddOn}
    </>
  )
}
