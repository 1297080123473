import { DatePickerInput } from '@mantine/dates'
import { createStyles } from '@mantine/styles'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { useColorTheme } from '../../hooks/useColorTheme/useColorTheme'
import { ClearButton } from '../ClearButton/ClearButton'
import { useDateTimeFmt } from '../../hooks'

import { DatePickerProps } from './DatePicker.types'
import { Icon, theme as lemonadeTheme } from '@percent/lemonade'

const StyledDatePicker = styled(DatePickerInput)<{
  disabled?: boolean
  error: boolean
}>`
  ${({ theme }) => `
      width: 100%;
      * {
        font-family: Inter, sans-serif !important;
        font-size: ${theme.fontSizes.bodySmall}
      };
      .mantine-InputPlaceholder-placeholder {
        color: ${theme.colors.gray300};
      };
      .mantine-DatePickerInput-input {
        padding-left: ${theme.sizes[8]} !important;
        transition: none;
        border-color: ${theme.colors.gray80};
        color: ${theme.colors.black} !important;
        &[data-disabled="true"] {
          background-color: ${theme.colors.gray80};
          outline: none;
          border: none;
          opacity: 1;
        };
        &:not([data-disabled="true"]):hover {
          outline: ${theme.sizes[1]} solid ${theme.colors.gray80};
          border-color: ${theme.colors.black};
        };
        &:not([data-disabled="true"]):not(data-error="true"):focus {
          border-color: ${theme.colors.black};
        };
        &[data-error="true"], [data-error="true"]:active, [data-error="true"]:focus {
          border-color: ${theme.colors.alert400} !important;
        };
        &[data-error="true"]:hover, [data-error="true"]:focus {
          outline: ${theme.sizes[1]} solid ${theme.colors.alert100};
          border-color: ${theme.colors.alert400} !important; 
        };
        &:not([data-disabled="true"]):not(data-error="true"):active {
          border-color: ${theme.colors.black};
        };
      }
  `}
`

export function DatePicker({
  fieldName,
  placeholder,
  handleChange,
  handleBlur,
  error,
  value,
  minDate,
  disabled,
  handleFieldClear,
  withinPopup = false,
  rangePicker = false
}: Readonly<DatePickerProps>) {
  const { theme: colorTheme } = useColorTheme()
  const { formatDateWODay } = useDateTimeFmt()

  const datePickerStyles = createStyles(() => ({
    day: {
      width: `${lemonadeTheme.sizes[8]} !important`,
      height: `${lemonadeTheme.sizes[8]} !important`,
      color: `${colorTheme.black} !important`,
      '&:hover:not([data-in-range]):not([data-selected])': {
        backgroundColor: `${colorTheme.info100} !important`
      },
      '&[data-in-range]': {
        backgroundColor: colorTheme.info100,
        '&:hover': {
          backgroundColor: colorTheme.primary,
          color: `${colorTheme.white} !important`
        }
      },
      '&[data-first-in-range], &[data-first-in-range][data-selected]': {
        borderRadius: rangePicker ? '100% 0 0 100% !important' : 'auto'
      },
      '&[data-last-in-range], &[data-last-in-range][data-selected]': {
        borderRadius: '0 100% 100% 0 !important'
      },
      '&[data-first-in-range][data-last-in-range]': {
        borderRadius: '100% !important'
      },
      '&[data-first-in-range], &[data-last-in-range]': {
        backgroundColor: colorTheme.primary,
        color: `${colorTheme.white} !important`,
        '&:hover': {
          backgroundColor: colorTheme.primary,
          color: `${colorTheme.white} !important`
        }
      },
      '&[data-selected]': {
        backgroundColor: `${colorTheme.primary} !important`
      },
      '&[data-outside]': {
        color: colorTheme.gray100,
        opacity: 1,

        '&[data-in-range], &[data-selected]': {
          color: colorTheme.gray900,
          '&:hover': {
            color: colorTheme.white
          }
        }
      }
    },
    weekDay: {
      color: `${colorTheme.gray600} !important`,
      fontWeight: `${lemonadeTheme.fontWeights.medium} !important` as unknown as number
    },
    calendarHeader: {
      fontSize: `${lemonadeTheme.fontSizes.bodySmall} !important`,
      fontWeight: `${lemonadeTheme.fontWeights.medium} !important` as unknown as number,
      color: `${colorTheme.black} !important`,
      '&:hover': {
        backgroundColor: `${colorTheme.info100} !important`
      }
    },
    calendar: {
      color: `${colorTheme.black} !important`,
      '&:hover': {
        backgroundColor: `${colorTheme.info100} !important`
      }
    },
    popover: {
      border: 'none !important',
      boxShadow: `${lemonadeTheme.boxShadows.medium} !important`,
      borderRadius: `${lemonadeTheme.borderRadii.large} !important`
    }
  }))

  const { classes } = datePickerStyles()

  return (
    <StyledDatePicker
      classNames={{
        day: classes.day,
        weekday: classes.weekDay,
        calendarHeaderLevel: classes.calendarHeader,
        calendarHeaderControlIcon: classes.calendar,
        calendarHeaderControl: classes.calendar,
        yearsListControl: classes.calendar,
        monthsListControl: classes.calendar
      }}
      type={rangePicker ? 'range' : 'default'}
      allowSingleDateInRange
      name={fieldName}
      value={value}
      minDate={minDate}
      dropdownType="popover"
      popoverProps={{
        zIndex: 2000,
        withinPortal: !withinPopup,
        position: 'bottom-start',
        classNames: {
          dropdown: classes.popover
        }
      }}
      placeholder={placeholder}
      error={error}
      onBlur={e => {
        handleBlur(e)
      }}
      onChange={date => {
        const isDateIncomplete = Array.isArray(date) && date.some(d => d === null)
        const isOnlyStartValuePresent = Array.isArray(value) && value[0] && !value[1]

        if (isOnlyStartValuePresent && isDateIncomplete) {
          return handleChange([value[0], value[0]])
        }

        return handleChange(date)
      }}
      disabled={disabled}
      leftSection={<Icon name="calendar" color="gray300" size={6} />}
      rightSection={
        handleFieldClear && (Array.isArray(value) ? !!value.filter(x => !!x)?.length : !!value) ? (
          <ClearButton testId={`clear-${fieldName}`} handleClick={handleFieldClear} />
        ) : undefined
      }
      valueFormatter={({ date }) => {
        if (Array.isArray(date)) {
          if (!date[0] || !date[1]) {
            return ''
          }

          if (dayjs(date[0]).startOf('day').isSame(dayjs(date[1]).startOf('day'))) {
            return formatDateWODay(date[0])
          }

          return `${formatDateWODay(date[0])} - ${formatDateWODay(date[1])}`
        }

        if (date) {
          return formatDateWODay(date)
        }

        return ''
      }}
    />
  )
}
