import { Typography, Box, Button as RawButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import identity from 'lodash/identity'
import { useDebounce } from 'usehooks-ts'
import { Alpha2Code } from 'i18n-iso-countries'

import { WorldFlagAvatar } from '../../WorldFlagAvatar/WorldFlagAvatar'

import { getStyles } from './RequestSubmitForm.styles'
import {
  Button,
  FormField,
  TextInput,
  FileInput,
  Icon,
  Alert,
  AsyncSelect,
  Checkbox,
  Select,
  FlagAvatar
} from '@percent/lemonade'
import { GsResponse, okResponse } from '@percent/workplace-giving/api/goodstack'
import {
  CreateDonationMatchRequest,
  DonationMatchRequest
} from '@percent/workplace-giving/api/donation-match-request/create/create-donation-match-request.types'
import { useAuth, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { searchOrganisations } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations'
import {
  Organisation,
  ORGANISATION_SEARCH_MAX_LENGTH
} from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { CurrencyCode, createShortLink } from '@percent/utility'
import countries from '@percent/workplace-giving/i18n/countries'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { CurrencyInput } from '@percent/domain/giving'
import { addProtocolToWebsiteUrl } from '@percent/workplace-giving/utils/url/url'
import { config } from '@percent/workplace-giving/config/config'
import { FILE_SIZE_2MB_HUMAN, FILE_SIZE_LIMIT_2MB } from '@percent/workplace-giving/constants/files'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { getShortLanguage } from '@percent/workplace-giving/utils/getShortLanguage'

interface RequestSubmitFormProps {
  currency: CurrencyCode
  apiFunc: MutationFn<CreateDonationMatchRequest, GsResponse<DonationMatchRequest>>
  onComplete: (_: DonationMatchRequest) => void
  handleClose: () => void
  defaultOrg?: Organisation
}

type ErrorCode =
  | 'upload/invalid_file_name'
  | 'upload/file_name_too_long'
  | 'upload/file_name_missing'
  | 'upload/file_type_mismatch'
  | 'upload/file_too_small'
  | 'upload/too_many_files'
  | 'upload/max_file_size'
  | 'upload/filetype_not_supported'
  | 'upload/file_is_missing'
  | 'upload/multipart_boundary_not_found'
  | 'upload/content_type_error'

export function RequestSubmitForm({ currency, apiFunc, onComplete, handleClose, defaultOrg }: RequestSubmitFormProps) {
  const { t, i18n } = useTranslation()
  const { logError } = useLogger()
  const [receipt, setReceipt] = useState<File | undefined>(undefined)
  const [receiptError, setReceiptError] = useState<string | undefined>(undefined)
  const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)
  const [showManualEntry, setManualEntry] = useState<boolean>(false)
  const intlConfigLang = i18n.language
  const orgFieldWrapper = useRef<HTMLDivElement | null>(null)
  const { validateString, validateUrl, validateNumber } = useSharedValidationRules()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  useEffect(() => {
    if (orgFieldWrapper.current) {
      const searchInput = orgFieldWrapper.current.querySelector('input')

      if (searchInput) {
        setTimeout(() => {
          searchInput.focus()
        }, 100)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgFieldWrapper?.current])

  const errorCodeMapper: Record<ErrorCode, string> = {
    'upload/file_is_missing': t('workplace_giving.errors.file.fileMissing'),
    'upload/content_type_error': t('workplace_giving.validation.invalidFileType', {
      fileFormats: config.acceptedReceiptFormatsHuman
    }),
    'upload/file_name_missing': t('workplace_giving.errors.file.invalidFilename'),
    'upload/file_name_too_long': t('workplace_giving.errors.file.invalidFilename'),
    'upload/file_too_small': t('workplace_giving.errors.file.invalidContents'),
    'upload/file_type_mismatch': t('workplace_giving.errors.file.fileTypeMismatch'),
    'upload/filetype_not_supported': t('workplace_giving.validation.invalidFileType', {
      fileFormats: config.acceptedReceiptFormatsHuman
    }),
    'upload/invalid_file_name': t('workplace_giving.errors.file.invalidFilename'),
    'upload/max_file_size': t('workplace_giving.validation.fileTooLarge', { fileSize: FILE_SIZE_2MB_HUMAN }),
    'upload/multipart_boundary_not_found': t('workplace_giving.errors.file.invalidContents'),
    'upload/too_many_files': t('workplace_giving.errors.file.tooManyFiles')
  }

  const authState = useAuth()
  const countryCode = defaultOrg?.countryCode || getCountryCodeFromAuthState(authState.state)!

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, getShortLanguage(i18n)),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [i18n, t]
  )

  const defaultCountryValue = alpha3CountryCodes.filter(a => a.value === countryCode)[0]!

  const [query, setQuery] = useState<string>('')
  const [searchCountryCode, setSearchCountryCode] = useState<string>(defaultCountryValue.value)

  const debouncedQuery = useDebounce(query, 200)

  const { data: searchData, isFetching } = useQuery(
    ['searchNonProfits', { query: debouncedQuery, pageSize: 10, countryCode: searchCountryCode }],
    searchOrganisations,
    { enabled: !!debouncedQuery }
  )
  const shortLanguage = getShortLanguage(i18n)

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('workplace_giving.common.id')}: ${organisation.registryId}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return searchData
      ? searchData.data.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [searchData, getOrganisationDescription])

  const defaultSearchResults: SelectOption[] = useMemo(() => {
    return defaultOrg
      ? [
          {
            value: defaultOrg.id,
            label: defaultOrg.name,
            description: getOrganisationDescription(defaultOrg)
          }
        ]
      : []
  }, [defaultOrg, getOrganisationDescription])

  const { mutateAsync } = useMutation<GsResponse<DonationMatchRequest>, AxiosError, CreateDonationMatchRequest>({
    mutationFn: apiFunc,
    onSuccess: response => {
      if ('data' in response && okResponse(response) && Object.keys(response.data).length !== 0) {
        onComplete(response.data)

        return
      }

      if ('error' in response && response.error.code in errorCodeMapper) {
        setReceiptError(errorCodeMapper[response.error.code as ErrorCode])
      } else {
        logError('error' in response && response.error)
        setUnexpectedError(`unexpected error: ${('error' in response && response.error?.message) || 'Unknown error'}`)
      }
    },
    onError: err => {
      logError(err)
      setUnexpectedError(`unexpected error`)
    }
  })

  const {
    errors,
    touched,
    dirty,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateForm
  } = useFormik({
    initialValues: {
      organisationIdOrName: defaultOrg?.id || defaultOrg?.displayName || '',
      organisationNotFoundName: '',
      organisationWebsite: defaultOrg?.website || '',
      amount: 0,
      currency,
      isExternalMatching: false,
      externalMatchingUrl: ''
    },
    validationSchema: Yup.object().shape({
      organisationIdOrName: validateString({ optional: showManualEntry }),
      organisationNotFoundName: validateString({ optional: !showManualEntry }),
      organisationWebsite: validateUrl(true),
      amount: validateNumber(),
      isExternalMatching: Yup.boolean(),
      externalMatchingUrl: Yup.string().when('isExternalMatching', {
        is: true,
        then: validateUrl()
      })
    }),
    onSubmit: data => {
      const { organisationIdOrName, amount, isExternalMatching, organisationWebsite, externalMatchingUrl, ...rest } =
        data

      return mutateAsync({
        ...rest,
        currency,
        amount,
        organisationId: !showManualEntry ? organisationIdOrName : undefined,
        organisationDetails: showManualEntry
          ? {
              name: data.organisationNotFoundName,
              ...(organisationWebsite !== '' && {
                websiteUrl: addProtocolToWebsiteUrl(organisationWebsite)
              })
            }
          : undefined,
        receipt: receipt!,
        externalMatchingUrl: isExternalMatching ? addProtocolToWebsiteUrl(externalMatchingUrl) : undefined
      })
    }
  })

  useEffect(() => {
    validateForm()
  }, [showManualEntry, validateForm])

  const formValid = showManualEntry
    ? dirty && isValid && !!receipt && !receiptError && !!values.organisationNotFoundName
    : isValid && !!receipt && !receiptError

  const handleFileChange = (file: File) => {
    setReceiptError(undefined)

    if (!/(png|jpe?g|pdf)/i.exec(file.type))
      setReceiptError(
        t('workplace_giving.validation.invalidFileType', { fileFormats: config.acceptedReceiptFormatsHuman })
      )

    if (file.size > FILE_SIZE_LIMIT_2MB)
      setReceiptError(t('workplace_giving.validation.fileTooLarge', { fileSize: FILE_SIZE_2MB_HUMAN }))

    setReceipt(file)
  }

  const resetFormValuesAfterCountryChange = useCallback(async () => {
    await setFieldValue('organisationIdOrName', '')
    await setFieldTouched('organisationIdOrName', false)
    validateForm()
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={Styles.Container}>
        <Box sx={Styles.Header}>
          <Typography id="donation-modal-title" variant="h2" sx={Styles.Title}>
            {t('workplace_giving.donationMatchRequest.title')}
          </Typography>
          <RawButton
            sx={Styles.ExitButton}
            type="button"
            aria-label={t('workplace_giving.common.close')}
            onClick={() => handleClose()}
            disableFocusRipple
          >
            <Icon name="close" size={4} color="gray600" />
          </RawButton>
        </Box>
        {unexpectedError && (
          <Alert variant="error" title={t('workplace_giving.errors.unexpected')}>
            {unexpectedError}
          </Alert>
        )}
        <Typography sx={Styles.Description}>{t('workplace_giving.donationMatchRequest.description')}</Typography>

        <Box sx={Styles.FormContent}>
          <FormField
            label={t('workplace_giving.donationMatchRequest.nonprofitLabel')}
            necessity="required"
            status={touched.organisationIdOrName && errors.organisationIdOrName ? 'danger' : 'default'}
            statusMessage={!showManualEntry ? errors.organisationIdOrName : undefined}
          >
            <Box sx={Styles.OrganisationSearchWrapper}>
              <Box sx={Styles.CountrySelectWrapper}>
                <Select
                  placeholder="Select country"
                  searchable
                  onChange={event => {
                    setSearchCountryCode(event.value)
                    setQuery('')
                    setManualEntry(false)
                    resetFormValuesAfterCountryChange()
                  }}
                  defaultValue={defaultCountryValue}
                  options={alpha3CountryCodes}
                  data-testid="countyCodeSelect"
                  showPrefixForSelectedOption
                />
              </Box>
              <Box width="100%" ref={orgFieldWrapper}>
                <AsyncSelect
                  defaultValue={defaultSearchResults[0]}
                  name="organisationIdOrName"
                  key={searchCountryCode}
                  placeholder={t('workplace_giving.donationMatchRequest.nonprofitPlaceholder')}
                  onChange={e => {
                    setFieldValue('organisationIdOrName', e?.value ?? '')
                    setFieldTouched('organisationIdOrName')

                    if (e) setManualEntry(false)
                  }}
                  options={[...defaultSearchResults, ...searchResults]}
                  maxLength={ORGANISATION_SEARCH_MAX_LENGTH}
                  setQuery={e => {
                    setQuery(e)
                    setFieldTouched('organisationIdOrName')
                    setFieldValue('organisationIdOrName', e)
                  }}
                  query={query}
                  addOption={{
                    label: t('workplace_giving.donationMatchRequest.search.cannotFind'),
                    onClick: () => {
                      setFieldValue('organisationIdOrName', '')
                      setFieldTouched('organisationIdOrName')
                      setManualEntry(true)
                    },
                    keepQuery: true
                  }}
                  loading={isFetching}
                  loadingText={t('workplace_giving.common.searching')}
                  noResultsFoundText={
                    query
                      ? t('workplace_giving.common.noResultsFound')
                      : t('workplace_giving.validation.emptyNonprofitNameQuery')
                  }
                  data-testid="organisationSearch"
                />
              </Box>
            </Box>
          </FormField>

          {showManualEntry && (
            <>
              <FormField
                label={t('workplace_giving.donationMatchRequest.nonprofitNameLabel')}
                necessity="required"
                data-testid="nonprofit-name"
                status={touched.organisationNotFoundName && errors.organisationNotFoundName ? 'danger' : 'default'}
                statusMessage={errors.organisationNotFoundName}
                description={t('workplace_giving.donationMatchRequest.nonprofitNameDescription')}
              >
                <TextInput
                  name="organisationNotFoundName"
                  placeholder={t('workplace_giving.donationMatchRequest.nonprofitNamePlaceholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.organisationNotFoundName}
                />
              </FormField>
              <FormField
                label={t('workplace_giving.donationMatchRequest.nonprofitWebsite.label')}
                data-testid="organisationWebsite"
                status={touched.organisationWebsite && errors.organisationWebsite ? 'danger' : 'default'}
                statusMessage={errors.organisationWebsite}
              >
                <TextInput
                  name="organisationWebsite"
                  placeholder={t('workplace_giving.donationMatchRequest.nonprofitWebsite.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.organisationWebsite}
                />
              </FormField>
            </>
          )}

          <FormField
            label={t('workplace_giving.donationMatchRequest.filePlaceholder')}
            necessity="required"
            status={receiptError ? 'danger' : 'default'}
            statusMessage={receiptError}
            data-testid="receipt"
          >
            <FileInput
              placeholder={t('workplace_giving.wizard.coverImage.placeholder', {
                acceptedFileFormats: config.acceptedReceiptFormatsHuman,
                maxFileSize: FILE_SIZE_2MB_HUMAN
              })}
              accept={config.acceptedReceiptFormats}
              dataTestId="receiptInput"
              onChange={file => handleFileChange(file)}
            />
          </FormField>
          <FormField
            label={t('workplace_giving.donationMatchRequest.amountPlaceholder')}
            necessity="required"
            status={touched.amount && errors.amount ? 'danger' : 'default'}
            statusMessage={errors.amount}
            data-testid="amountInput"
          >
            <CurrencyInput
              id="amount"
              name="amount"
              status={touched.amount && errors.amount ? 'danger' : 'default'}
              value={values?.amount ? { amount: values.amount, currency } : undefined}
              onValueChange={value => setFieldValue('amount', value?.amount)}
              currency={currency}
              locale={intlConfigLang}
              onBlur={handleBlur}
              allowNegativeValue={false}
              placeholder={{ amount: 0, currency }}
            />
          </FormField>

          <Checkbox
            name="isExternalMatching"
            label={t('workplace_giving.donationMatchRequest.externalMatchingRequest')}
            variant="default"
            active={values.isExternalMatching}
            onChange={handleChange}
          />
          {values.isExternalMatching && (
            <FormField
              label={t('workplace_giving.donationMatchRequest.externalMatchingUrl.label')}
              data-testid="externalMatchingUrl"
              status={touched.externalMatchingUrl && errors.externalMatchingUrl ? 'danger' : 'default'}
              statusMessage={errors.externalMatchingUrl}
              description={t('workplace_giving.donationMatchRequest.externalMatchingUrl.description')}
              necessity="required"
            >
              <TextInput
                name="externalMatchingUrl"
                placeholder={t('workplace_giving.donationMatchRequest.externalMatchingUrl.placeholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.externalMatchingUrl || ''}
              />
            </FormField>
          )}

          <Box sx={Styles.SubmitButtonWrapper}>
            <Button
              variant="secondary"
              disabled={isSubmitting}
              data-testid="cancel"
              size="medium"
              type="button"
              onPress={() => handleClose()}
            >
              {t('workplace_giving.common.cancel')}
            </Button>
            <Button loading={isSubmitting} data-testid="submit" size="medium" type="submit" disabled={!formValid}>
              {t('workplace_giving.donationMatchRequest.submitButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}
