import { Box, Card, Text } from '@mantine/core'

import { SignInCardProps } from './SignInCard.types'
import classes from './SignInCard.module.scss'

export function SignInCard({ title, description, children }: SignInCardProps) {
  return (
    <Box className={classes.Wrapper}>
      <Card className={classes.Card}>
        <Text component="h2" className={classes.Title}>
          {title}
        </Text>
        {description ? <Text className={classes.Description}>{description}</Text> : null}
        <Box className={classes.ContentWrapper}>{children}</Box>
      </Card>
    </Box>
  )
}
