import { createContext } from 'react'

import { ColorPaletteType } from '../colorThemeContextController/ColorThemeContextController'

import { CssVariablesColorPalette } from '@percent/workplace-giving/utils/colorPaletteToCssVariables'

type ColorThemeContext = {
  theme: ColorPaletteType
  cssVariables: CssVariablesColorPalette
  setTheme: (theme: ColorPaletteType) => void
}

export const ColorThemeContext = createContext<ColorThemeContext | undefined>(undefined)
