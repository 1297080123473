import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Container: {
    boxSizing: 'border-box',
    backgroundColor: lemonadeTheme.colors.white,
    borderRadius: '8px',
    overflow: 'hidden',

    '& > *': {
      fontFamily: 'Inter, sans-serif !important'
    }
  },

  ContentWrapper: {
    boxSizing: 'border-box',
    maxHeight: '92vh',
    padding: '32px 24px 32px',
    overflowY: 'auto',

    '@media (min-width: 768px)': {
      padding: '40px 56px 32px'
    }
  },

  Header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: lemonadeTheme.sizes[5]
  },

  Icon: {
    fontSize: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '72px',
    width: '72px',
    minWidth: '72px',
    marginLeft: lemonadeTheme.sizes[2],
    borderRadius: '50%',
    backgroundColor: lemonadeTheme.colors.warning100
  },

  Title: {
    fontWeight: '600',
    fontSize: lemonadeTheme.fontSizes.h2,
    lineHeight: lemonadeTheme.sizes[8],
    color: lemonadeTheme.colors.black
  },

  Description: {
    fontWeight: '400',
    fontSize: lemonadeTheme.fontSizes.body,
    lineHeight: lemonadeTheme.sizes[6],
    color: customPalette.gray600
  },

  Form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    a: {
      color: customPalette.primary400
    }
  },

  ErrorMessage: {
    color: customPalette.alert400
  },

  Wave: {
    animationName: 'wave-animation',
    animationDuration: '2.5s',
    animationIterationCount: 'infinite',
    transformOrigin: '70% 70%',
    display: 'inline-block',

    '@keyframes wave-animation': {
      '0%': { transform: 'rotate( 0.0deg)' },
      '10%': { transform: 'rotate(14.0deg)' },
      '20%': { transform: 'rotate(-8.0deg)' },
      '30%': { transform: 'rotate(14.0deg)' },
      '40%': { transform: 'rotate(-4.0deg)' },
      '50%': { transform: 'rotate(10.0deg)' },
      '60%': { transform: 'rotate( 0.0deg)' },
      '100%': { transform: 'rotate( 0.0deg)' }
    }
  },

  Spacer: {
    height: '1px',
    width: '100%',
    backgroundColor: lemonadeTheme.colors.gray80
  },

  ButtonsWrapper: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    gap: lemonadeTheme.sizes[2],
    marginTop: lemonadeTheme.sizes[6]
  },

  TermsInfo: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6]
  }
})
