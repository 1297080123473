import type { ListResponse } from '../../goodstack'
import { SearchOpportunitiesResponseRow } from '../../search/searchOpportunities/searchOpportunities.types'

import type { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { GetCampaignOpportunitiesQueryParams } from './getCampaignOpportunities.types'

export const getCampaignOpportunities: InfiniteQueryFn<
  [string, GetCampaignOpportunitiesQueryParams],
  string,
  ListResponse<SearchOpportunitiesResponseRow>
> = async (client, [_, { campaignId, direction = 'ASC', pageSize = 25 }], cursor) =>
  (
    await (cursor
      ? client.get<ListResponse<SearchOpportunitiesResponseRow>>(cursor, {
          withCredentials: true
        })
      : client.get<ListResponse<SearchOpportunitiesResponseRow>>(`/giving-campaign/${campaignId}/opportunities`, {
          params: { direction, pageSize },
          withCredentials: true
        }))
  ).data
