import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'
import { useContext, useEffect, useState } from 'react'
import { isEqual } from 'lodash'

import { splitSdkConfig, Feature, featureNames, features } from '@percent/workplace-giving/providers/SplitSdkProvider'

export function useFeatureFlag() {
  const treatments = useTreatments(Object.keys(splitSdkConfig.features))
  const { isReady, isTimedout } = useContext(SplitContext)

  const [featureFlags, setFeatureFlags] = useState<Record<Feature, boolean>>(() =>
    featureNames.reduce((acc, feature) => {
      acc[feature] = features[feature].defaultValue === 'on'

      return acc
    }, {} as Record<Feature, boolean>)
  )

  const [featureFlagsReady, setFeatureFlagsReady] = useState(false)

  useEffect(() => {
    if ((isReady || isTimedout) && Object.keys(treatments).length > 0) {
      const newFeatureFlags = featureNames.reduce((acc, feature) => {
        acc[feature] =
          (isReady && treatments[features[feature].key]?.treatment === 'on') ||
          (isTimedout && features[feature].defaultValue === 'on')

        return acc
      }, {} as Record<Feature, boolean>)

      setFeatureFlags(prevFlags => {
        if (!isEqual(prevFlags, newFeatureFlags)) {
          setFeatureFlagsReady(true)

          return newFeatureFlags
        }

        return prevFlags
      })
    }
  }, [isReady, isTimedout, treatments])

  return { ...featureFlags, _meta: { isReady: isReady && featureFlagsReady } }
}
