import { Box } from '@mantine/core'
import { clsx } from '@mantine/styles'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganisationSearchBar } from '../Search/SearchBar/SearchBar'

import { WelcomeHeader } from './WelcomeHeader/WelcomeHeader'
import classes from './Home.module.scss'
import { Upcoming } from './Upcoming/Upcoming'
import { TotalVolunteeredCard } from './TotalVolunteeredCard/TotalVolunteeredCard'
import { SuggestedOpportunitiesRow } from './SuggestedOpportunitiesRow/SuggestedOpportunitiesRow'
import { useScrolled } from './useScrolled'
import { TotalDonatedCard } from './TotalDonatedCard/TotalDonatedCard'
import { FoundationRewards } from './FoundationRewards/FoundationRewards'
import { usePermission } from '@percent/workplace-giving/common/hooks/usePermission/usePermission'
import { feature, scope } from '@percent/workplace-giving/common/hoc/withPermissions'
import { SuggestedFundraisersRow } from './SuggestedFundraisersRow/SuggestedFundraisersRow'
import { MatchedDonationCard } from './MatchedDonationCard/MatchedDonationCard'
import { Loader, Tab, Tabs } from '@percent/lemonade'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { useFeatureFlag } from '@percent/workplace-giving/common/hooks/useFeatureFlag/useFeatureFlag'
import { DashboardCard } from './DashboardCard/DashboardCard'
import { useOrganisationSearchBar } from './useOrganisationSearchBar'
import { LatestDonations } from './LatestDonations/LatestDonations'
import { CampaignCard } from '@percent/workplace-giving/common/components/CampaignCard/CampaignCard'
import { getCampaigns } from '@percent/workplace-giving/api/campaigns/getCampaigns/getCampaigns'

enum MyImpactTab {
  UPCOMING = 'upcoming',
  LATEST_DONATIONS = 'latest-donations'
}

export function Home() {
  const scrolled = useScrolled()

  const has = usePermission()
  const volunteeringEnabled = has(scope('volunteering'))
  const gamificationEnabled = has(scope('gamification'), feature('gamification'))
  const fundraisersEnabled = has(scope('donation_link'))
  const donationsEnabled = has(scope('donation_link')) || has(scope('donation_wallet'))
  const donationMatchingEnabled = has(scope('donation_matching'))
  const { campaigns: campaignsEnabled } = useFeatureFlag()
  const { t } = useTranslation()

  const { data: campaigns, isLoading: isLoadingCampaigns } = useQuery(['getCampaigns'], getCampaigns, {
    staleTime: 1000 * 60,
    enabled: campaignsEnabled
  })

  const [activeTab, setActiveTab] = useState<MyImpactTab>(MyImpactTab.UPCOMING)
  const items = useMemo(() => {
    return [
      {
        key: MyImpactTab.UPCOMING,
        label: t('workplace_giving.upcoming.title')
      },
      {
        key: MyImpactTab.LATEST_DONATIONS,
        label: t('workplace_giving.latestDonations.title')
      }
    ]
  }, [t])

  const onChangeTab = useCallback((tab: string) => setActiveTab(tab as MyImpactTab), [])

  const searchBarProps = useOrganisationSearchBar()

  if (campaignsEnabled && isLoadingCampaigns) {
    return <Loader />
  }

  return (
    <Box className={classes.PageContainer} data-testid="home-page-wrapper">
      <WelcomeHeader scrolled={scrolled} tall />
      <Box className={classes.ContentWrapper}>
        <Box className={classes.SearchWrapper}>
          <OrganisationSearchBar {...searchBarProps} size="medium" />
        </Box>
        <Box
          className={clsx(classes.CardsWrapper, {
            [classes.WithCampaigns]: !!campaigns
          })}
        >
          {campaigns?.[0] ? <CampaignCard {...campaigns[0]} /> : null}
          {volunteeringEnabled && donationsEnabled ? (
            <Box>
              <DashboardCard testId="upcoming-and-latest-donations-wrapper" fullHeight>
                <Tabs items={items} activeTab={activeTab} onChangeTab={onChangeTab} />
                <Tab index={MyImpactTab.UPCOMING} data-testid="upcoming-tab" value={activeTab}>
                  <Upcoming />
                </Tab>
                <Tab
                  index={MyImpactTab.LATEST_DONATIONS}
                  data-testid="latest-donations-tab"
                  value={activeTab}
                  style={{
                    maxHeight: '100%',
                    overflow: 'hidden'
                  }}
                >
                  <LatestDonations />
                </Tab>
              </DashboardCard>
            </Box>
          ) : volunteeringEnabled ? (
            <DashboardCard testId="upcoming-wrapper" fullHeight>
              <Upcoming removeTab />
            </DashboardCard>
          ) : donationsEnabled ? (
            <DashboardCard testId="latest-donations-wrapper" fullHeight>
              <LatestDonations removeTab />
            </DashboardCard>
          ) : null}
          <Box className={classes.SmallCardsWrapper}>
            <Box
              className={clsx(classes.TotalsWrapper, {
                [classes.WithGamification]: (gamificationEnabled || donationMatchingEnabled) && !campaigns
              })}
            >
              {volunteeringEnabled && <TotalVolunteeredCard />}
              {donationsEnabled && <TotalDonatedCard />}
            </Box>
            {donationMatchingEnabled && !gamificationEnabled && !campaigns && (
              <Box className={classes.GamificationWrapper}>
                <MatchedDonationCard />
              </Box>
            )}
            {gamificationEnabled && !campaigns && (
              <Box className={classes.GamificationWrapper} data-testid="gamification-section">
                <FoundationRewards />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {volunteeringEnabled && (
        <Box className={classes.PageContent}>
          <SuggestedOpportunitiesRow />
        </Box>
      )}
      {fundraisersEnabled && (
        <Box className={classes.PageContent}>
          <SuggestedFundraisersRow />
        </Box>
      )}
    </Box>
  )
}
