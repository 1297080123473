import { Box, Typography } from '@mui/material'
import { Divider } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { Button, Modal } from '@percent/lemonade'
import { ModalProps } from 'libs/shared/ui-lemonade/src/components/modal'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'

type LogVolunteeringActivityCancellationModalProps = Pick<ModalProps, 'open'> & {
  onClose: VoidFunction
  isEditMode?: boolean
}

export function LogVolunteeringActivityCancellationModal({
  open,
  onClose,
  isEditMode
}: Readonly<LogVolunteeringActivityCancellationModalProps>) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { theme } = useColorTheme()

  return (
    <Modal onClose={onClose} open={open}>
      <Box padding={3}>
        <Typography fontSize={20} fontWeight={600}>
          {t('workplace_giving.wizard.confirmCancellation')}
        </Typography>
        <Divider my={16} />
        <Typography fontSize={14} color={theme.neutral300}>
          {t(
            isEditMode
              ? 'workplace_giving.volunteering.activityForm.cancelEdit.modal'
              : 'workplace_giving.volunteering.activityForm.cancel.modal'
          )}
        </Typography>
        <Box mt={4} display="flex" justifyContent="end" gap={2}>
          <Button variant="secondary" onPress={onClose}>
            {t('workplace_giving.wizard.continueEditing')}
          </Button>
          <Button
            variant="primary"
            onPress={() => {
              onClose()

              if (isEditMode) {
                navigate(AppRoute.MY_IMPACT, { state: { activeTab: 'volunteer' } })
              } else {
                navigate(-1)
              }
            }}
          >
            {t('workplace_giving.common.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
