import { alpha2ToAlpha3, Alpha3Code } from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import { Box, Skeleton, Text } from '@mantine/core'

import classes from '../Campaign.module.scss'

import { useInfiniteQuery } from '@percent/workplace-giving/common/hooks'
import { getCampaignOrganisations } from '@percent/workplace-giving/api/campaigns/getCampaignOrganisations/getCampaignOrganisations'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'
import { OrganisationCardActions } from '@percent/workplace-giving/common/components/OrganisationCardActions/OrganisationCardActions'
import { OrganisationCard } from '@percent/domain/giving'
import { CampaignOrganisation } from '@percent/workplace-giving/api/campaigns/getCampaignOrganisations/getCampaignOrganisations.types'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { useDonationForm } from '@percent/workplace-giving/common/hooks/useDonationForm/useDonationForm'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { SearchResultCardSkeleton } from '@percent/workplace-giving/common/components'
import { mapInfiniteData } from '@percent/workplace-giving/utils/mapInfiniteData'

export function CampaignOrganisations({ campaignId }: Readonly<{ campaignId: string }>) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const { track } = useAnalytics()

  const { handleDonate, DonationFormWrapper } = useDonationForm('regular')

  const { data, isFetching } = useInfiniteQuery(
    ['getCampaignOrganisations', { campaignId, pageSize: 10 }],
    getCampaignOrganisations,
    { staleTime: 1000 * 20 }
  )

  const mappedData = mapInfiniteData(data)

  const handleDonateClick = (organisation: CampaignOrganisation) => {
    track({
      event: 'Donation Organisation Donation Start',
      properties: {
        origin: 'Campaign',
        organisationCountryCode: organisation.countryCode
      }
    })
    handleDonate(organisation.organisationId)
  }

  if (isFetching) {
    return (
      <Box className={classes.SectionWrapper} data-testid="campaign-organisations-section-loader">
        <Skeleton height="2rem" width="55%" />
        <SearchResultCardSkeleton />
      </Box>
    )
  }

  if (mappedData) {
    return (
      <Box className={classes.SectionWrapper} data-testid="campaign-organisations-section">
        <Text className={classes.SectionTitle}>{t('workplace_giving.campaigns.supportNonprofits')}</Text>
        {mappedData.pages.map(p =>
          p.map(o => (
            <OrganisationCard
              key={o.organisationId}
              id={o.organisationId}
              iconUrl={o.logoUrl ?? undefined}
              title={o.displayName ?? o.name}
              description={o.description}
              countryCode={o.countryCode}
              website={o.website}
              onDonateClicked={() => handleDonateClick(o)}
              buttonText={t('workplace_giving.common.donate')}
              moreText={t('workplace_giving.common.more')}
              lessText={t('workplace_giving.common.less')}
              registryId={
                o?.organisationIdInRegistry
                  ? t('workplace_giving.common.registryId', {
                      registryId: o?.organisationIdInRegistry
                    })
                  : undefined
              }
              cardButtonInfo={<TooltipTaxInformation orgCountryCode={alpha2ToAlpha3(o.countryCode) as Alpha3Code} />}
              additionalActions={
                <OrganisationCardActions
                  organisation={{
                    ...o,
                    id: o.organisationId,
                    logoUrl: o.logoUrl || null,
                    countryCode: alpha2ToAlpha3(o.countryCode) as Alpha3Code
                  }}
                />
              }
            />
          ))
        )}
        <DonationFormWrapper
          params={{
            primaryColor: theme.primary400
          }}
        />
      </Box>
    )
  }

  return null
}
