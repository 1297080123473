import { Box, Typography } from '@mui/material'
import { MultiSelect, NumberInput } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import type { FormikErrors, FormikTouched } from 'formik'
import { useState, type FocusEvent } from 'react'

import { AsyncSelect, FormField, Icon, Loader, Select } from '@percent/lemonade'
import { DatePicker } from '@percent/workplace-giving/common/components/DatePicker/DatePicker'
import { getStyles } from './LogVolunteeringActivityForm.styles'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import type { LogVolunteeringActivityFormFields } from './useLogVolunteeringActivityForm'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import { VolunteeringActivityDetails } from '@percent/workplace-giving/api/volunteering/getVolunteeringActivity/getVolunteeringActivity'

export type LogOffPlatformActivityFormProps = Readonly<{
  alpha3CountryCodes: SelectOption[]
  activitiesOptions: SelectOption[]
  isFetching: boolean
  organisationQuery: string
  resetOrgIdFieldAfterCountryChange: () => Promise<void>
  searchCountryCode: string
  searchResults: SelectOption[]
  setOrganisationQuery: (query: string) => void
  setSearchCountryCode: (countryCode: string) => void
  isLoading: boolean
  skillsOptions: SelectOption[]
  defaultCountryValue: SelectOption
  values: LogVolunteeringActivityFormFields
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  touched: FormikTouched<LogVolunteeringActivityFormFields>
  errors: FormikErrors<LogVolunteeringActivityFormFields>
  handleBlur: <A = HTMLInputElement>(e: FocusEvent<A>) => void
  isEditMode?: boolean
  editedTimeLogDetails?: VolunteeringActivityDetails
  defaultOrganisationValue?: SelectOption
}>

export function LogOffPlatformActivityForm({
  alpha3CountryCodes,
  activitiesOptions,
  isFetching,
  organisationQuery,
  searchResults,
  skillsOptions,
  defaultCountryValue,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  handleBlur,
  isLoading,
  resetOrgIdFieldAfterCountryChange,
  searchCountryCode,
  setSearchCountryCode,
  setOrganisationQuery,
  isEditMode,
  editedTimeLogDetails,
  defaultOrganisationValue
}: LogOffPlatformActivityFormProps) {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)
  const [selectIcon, setSelectIcon] = useState<IconProps['name']>('dropdown-arrow-down')

  return (
    <>
      <Box>
        <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.activityForm.dateAndTime')}</Typography>
        <FormField
          label={t('workplace_giving.volunteering.activityForm.date.label')}
          necessity="required"
          status={touched.date && errors.date ? 'danger' : 'default'}
          statusMessage={errors.date}
          data-testid="dateField"
        >
          <DatePicker
            fieldName="date"
            placeholder={t('workplace_giving.volunteering.activityForm.date.placeholder')}
            value={values.date}
            handleBlur={handleBlur}
            handleChange={async date => {
              setFieldTouched('date')
              setFieldValue('date', date)
            }}
            error={!!errors.date && !!touched.date}
          />
        </FormField>

        <Box sx={Styles.FlexWrapper}>
          <FormField
            label={t('workplace_giving.volunteering.activityForm.hours.label')}
            necessity="required"
            status={touched.hours && errors.hours ? 'danger' : 'default'}
            statusMessage={errors.hours}
            data-testid="hoursField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="hours"
              min={0}
              max={24}
              step={1}
              hideControls
              allowNegative={false}
              allowDecimal={false}
              placeholder={t('workplace_giving.volunteering.activityForm.hours.placeholder')}
              value={values.hours}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('hours', value)
              }}
              error={!!errors.hours && !!touched.hours}
            />
          </FormField>

          <FormField
            label={t('workplace_giving.volunteering.activityForm.minutes.label')}
            necessity="required"
            status={touched.minutes && errors.minutes ? 'danger' : 'default'}
            statusMessage={errors.minutes}
            data-testid="minutesField"
          >
            <NumberInput
              style={{ width: '100%' }}
              name="minutes"
              min={0}
              max={59}
              step={1}
              hideControls
              allowNegative={false}
              allowDecimal={false}
              placeholder={t('workplace_giving.volunteering.activityForm.minutes.placeholder')}
              defaultValue={0}
              value={values.minutes}
              allowLeadingZeros={false}
              onBlur={e => {
                handleBlur(e)
                setFieldTouched('hours')
              }}
              onChange={value => {
                setFieldValue('minutes', value)
              }}
              error={!!errors.minutes && !!touched.minutes}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Typography sx={Styles.SectionTitle}>
          {t('workplace_giving.volunteering.activityForm.volunteerActivity')}
        </Typography>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FormField
              label={t('workplace_giving.volunteering.activityForm.activityTags.label')}
              necessity="required"
              data-testid="activitiesField"
              status={touched.activities && errors.activities ? 'danger' : 'default'}
              statusMessage={errors.activities}
            >
              <MultiSelect
                wrapperProps={{
                  'data-error': Boolean(touched.activities && errors.activities)
                }}
                style={{ width: '100%' }}
                placeholder={
                  !values.activities?.length
                    ? t('workplace_giving.volunteering.activityForm.activityTags.placeholder')
                    : ''
                }
                searchable
                data={activitiesOptions}
                value={values.activities}
                onChange={selectedValues => {
                  setFieldValue('activities', selectedValues)
                }}
                rightSection={<Icon name={selectIcon} size={8} color="gray600" />}
                onDropdownOpen={() => {
                  setSelectIcon('dropdown-arrow-up')
                }}
                onDropdownClose={() => {
                  setSelectIcon('dropdown-arrow-down')
                }}
                onClick={() => setFieldTouched('activities')}
              />
            </FormField>
            <FormField label={t('workplace_giving.wizard.skills.label')} necessity="optional" data-testid="skillsField">
              <MultiSelect
                style={{ width: '100%' }}
                placeholder={!values.skills?.length ? 'e.g. Graphic design' : ''}
                searchable
                data={skillsOptions}
                value={values.skills}
                onChange={selectedValues => {
                  setFieldValue('skills', selectedValues)
                }}
                rightSection={<Icon name={selectIcon} size={8} color="gray600" />}
                onDropdownOpen={() => {
                  setSelectIcon('dropdown-arrow-up')
                }}
                onDropdownClose={() => {
                  setSelectIcon('dropdown-arrow-down')
                }}
              />
            </FormField>
          </>
        )}
      </Box>
      <Box>
        <Typography sx={Styles.SectionTitle}>
          {t('workplace_giving.volunteering.activityForm.supportedNonprofit')}
        </Typography>

        <FormField
          necessity="optional"
          label={t('workplace_giving.volunteering.activityForm.nonprofitCountry.label')}
          description={t('workplace_giving.wizard.nonprofitCountry.description')}
          status={touched.organisationCountry && errors.organisationCountry ? 'danger' : 'default'}
          statusMessage={errors.organisationCountry}
          data-testid="organisationCountryField"
          disabled={isEditMode}
        >
          <Select
            status={errors.organisationCountry ? 'danger' : 'default'}
            placeholder={t('workplace_giving.wizard.nonprofitCountry.placeholder')}
            options={alpha3CountryCodes}
            defaultValue={
              editedTimeLogDetails?.organisation?.countryCode
                ? alpha3CountryCodes.find(a => a.value === editedTimeLogDetails?.organisation?.countryCode)
                : defaultCountryValue
            }
            onChange={event => {
              setFieldValue('organisationCountry', event.value)
              setSearchCountryCode(event.value)
              resetOrgIdFieldAfterCountryChange()
            }}
            searchable
            showPrefixForSelectedOption
          />
        </FormField>

        <FormField
          necessity="optional"
          label={t('workplace_giving.volunteering.activityForm.findNonprofit.label')}
          status={touched.organisationId && errors.organisationId && !organisationQuery ? 'danger' : 'default'}
          statusMessage={errors.organisationId}
          data-testid="organisationSearchField"
          disabled={isEditMode}
        >
          <AsyncSelect
            status={errors.organisationId && !organisationQuery ? 'danger' : 'default'}
            defaultValue={isEditMode ? searchResults[0] : defaultOrganisationValue}
            name="organisationId"
            key={searchCountryCode}
            placeholder={t('workplace_giving.wizard.selectNonprofit.placeholder')}
            onChange={e => {
              setFieldValue('organisationId', e?.value ?? '')
              setFieldTouched('organisationId')
            }}
            options={searchResults}
            setQuery={e => {
              setOrganisationQuery(e)
              setFieldTouched('organisationId')
            }}
            query={organisationQuery}
            loading={isFetching}
            loadingText={t('workplace_giving.common.searching')}
            noResultsFoundText={
              organisationQuery
                ? t('workplace_giving.common.noResultsFound')
                : t('workplace_giving.validation.emptyNonprofitNameQuery')
            }
            data-testid="organisationSearch"
          />
        </FormField>
      </Box>
    </>
  )
}
