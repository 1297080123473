import { Box, Skeleton } from '@mantine/core'

import { LATEST_DONATIONS_COUNT } from '../LatestDonations'

export function SkeletonLoader() {
  return (
    <Box data-testid="latestDonationsSkeletonLoader">
      {Array.from({ length: LATEST_DONATIONS_COUNT }).map((_, idx) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`latest-donations-skeleton-item-${idx}`}
          style={{ display: 'flex', flexFlow: 'row', gap: '0.75rem', padding: '5px 0' }}
        >
          <Skeleton height={30} display="inline-block" width="17%" />
          <Skeleton height={30} display="inline-block" width="83%" />
        </Box>
      ))}
    </Box>
  )
}
