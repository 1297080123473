import { useEffect } from 'react'

import { useAuth, useDonationSummary, useLogger } from '@percent/workplace-giving/common/hooks'
import { Money } from '@percent/utility'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'

export type useMatchedDonationProps = Readonly<{
  isLoading: boolean
  matchDonation: Money
  partnerName: string
}>

export const useMatchedDonationCard = (): useMatchedDonationProps => {
  const { state: authState } = useAuth()
  const { logError } = useLogger()
  const accountConfig = getAccountConfigFromAuthState(authState)
  const partner = getPartnerFromAuthState(authState)

  const { data, isLoading, error } = useDonationSummary()

  const currencyCode = accountConfig?.currency ?? partner?.currencyCode

  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error, logError])

  return {
    isLoading,
    matchDonation: data?.matched ?? { amount: 0, currency: currencyCode ?? 'USD' },
    partnerName: partner?.name ?? ''
  }
}
