/* eslint-disable jsx-a11y/accessible-emoji */
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useEffect, useMemo, useState } from 'react'
import { alpha3ToAlpha2 } from 'i18n-iso-countries'

import { DonationWalletGiftPopup } from '../DonationWalletGiftPopup/DonationWalletGiftPopup'

import { FlagAvatar } from '@percent/lemonade'
import { onboarding } from '@percent/workplace-giving/api/onboarding/onboarding'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { useAuth, useQuery } from '@percent/workplace-giving/common/hooks'
import {
  getAcceptedTermsFromAuthState,
  getAccountConfigFromAuthState,
  getAccountFromAuthState,
  getCountryCodeFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { Terms } from '@percent/workplace-giving/context/auth/authContext/AuthContext.types'
import { getCurrencies } from '@percent/workplace-giving/api/currency/currencies'
import { OnboardingPopupModal } from './OnboardingPopupModal'
import { getPartnerCurrencies } from '@percent/workplace-giving/api/currency/supported-currencies/supported-currencies'
import { useDonationWallet } from '@percent/workplace-giving/common/hooks/useDonationWallet/useDonationWallet'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'

const EXPECTED_TERMS: Terms[] = ['workplace:terms:2024-01-01', 'percent:privacy:2024-01-01']

export function OnboardingPopup() {
  const { t } = useTranslation()
  const { state, dispatch } = useAuth()
  const user = getAccountFromAuthState(state)
  const terms = getAcceptedTermsFromAuthState(state)
  const userCountry = getCountryCodeFromAuthState(state)
  const accountConfig = getAccountConfigFromAuthState(state)
  const { data: wallet, refetch: refetchWallet } = useDonationWallet()
  const { validateString } = useSharedValidationRules()

  const [displayOnboardingPopup, setDisplayOnboardingPopup] = useState(false)
  const [error, setError] = useState('')
  const [displayDonationWalletGiftPopup, setDisplayDonationWalletGiftPopup] = useState(false)

  const { data: currencyInfo, isLoading: currencyInfoLoading } = useQuery(['getCurrencies'], getCurrencies, {
    enabled: !accountConfig?.currency
  })

  const { data: partnerCurrencyInfo, isLoading: partnerCurrencyInfoLoading } = useQuery(
    ['getPartnerCurrencies'],
    getPartnerCurrencies,
    {
      enabled: !accountConfig?.currency
    }
  )

  useEffect(() => {
    const hasCompleteNameInfo = !!user?.fullName && !!user?.preferredName
    const hasAcceptedTerms = EXPECTED_TERMS.every(term => terms?.includes(term) ?? false)
    const needsCurrency = !accountConfig?.currency
    setDisplayOnboardingPopup(!hasCompleteNameInfo || !hasAcceptedTerms || needsCurrency)
  }, [user, terms, accountConfig?.currency])

  const currencyOptions = useMemo(() => {
    if (partnerCurrencyInfo) {
      return currencyInfo
        ?.filter(currency => !!currency.supportedByStripe && partnerCurrencyInfo.includes(currency.code))
        .map(currency => ({
          prefix: <FlagAvatar code={currency.flagCode} />,
          label: `${currency.name} (${currency.code})`,
          value: currency.code,
          flag: currency.flagCode
        }))
    }

    return currencyInfo
      ?.filter(currency => !!currency.supportedByStripe)
      .map(currency => ({
        prefix: <FlagAvatar code={currency.flagCode} />,
        label: `${currency.name} (${currency.code})`,
        value: currency.code,
        flag: currency.flagCode
      }))
  }, [currencyInfo, partnerCurrencyInfo])

  const defaultCurrencyOption = useMemo(() => {
    if (accountConfig?.currency) {
      return currencyOptions?.find(option => option.value === accountConfig.currency)
    }

    if (userCountry) {
      return currencyOptions?.find(option => option.flag === alpha3ToAlpha2(userCountry))
    }

    if (currencyOptions && currencyOptions.length > 0) {
      return currencyOptions[0]
    }

    return undefined
  }, [accountConfig?.currency, currencyOptions, userCountry])

  useEffect(() => {
    if (displayOnboardingPopup || displayDonationWalletGiftPopup) {
      document.body.setAttribute('style', `overflow-y:hidden`)
    }
  }, [displayDonationWalletGiftPopup, displayOnboardingPopup])

  const { mutateAsync } = useMutation({
    mutationFn: onboarding,
    onSuccess: res => {
      if (state.status === 'authorized') {
        try {
          dispatch({
            type: 'UPDATE',
            payload: {
              account: res.account,
              partner: state.partner,
              acceptedTerms: res.terms,
              accConfig: res.accConfig
            }
          })

          setDisplayOnboardingPopup(false)

          // Refetch wallet data after successful onboarding
          refetchWallet().then(result => {
            if (result.data?.balance?.amount) {
              setDisplayDonationWalletGiftPopup(true)
            }
          })
        } catch {
          setError(t('workplace_giving.errors.unexpected'))
        }
      }
    },
    onError: () => {
      setError(t('workplace_giving.errors.unexpected'))
    }
  })

  const showOnboardingPopupSkeleton = !accountConfig?.currency && (currencyInfoLoading || partnerCurrencyInfoLoading)
  const showCurrencyOptions =
    currencyOptions !== undefined && (accountConfig?.currency === undefined || accountConfig?.currency === null)

  const {
    dirty,
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue
  } = useFormik({
    initialValues: {
      showCurrencyOptions,
      fullName: user?.fullName || '',
      preferredName: user?.preferredName || '',
      preferredCurrency: accountConfig?.currency ? undefined : defaultCurrencyOption?.value,
      termsAndConditions: false
    },
    validationSchema: () =>
      Yup.object().shape({
        showCurrencyOptions: Yup.boolean(),
        fullName: validateString(),
        preferredName: validateString(),
        preferredCurrency: validateString({ optional: !values.showCurrencyOptions }),
        termsAndConditions: Yup.boolean().oneOf([true], t('workplace_giving.validation.requiredField'))
      }),
    onSubmit: ({ fullName, preferredName, preferredCurrency }) => {
      setError('')
      document.body.setAttribute('style', 'overflow-y:auto')

      return mutateAsync({
        fullName: fullName.trim(),
        preferredName: preferredName.trim(),
        currency: preferredCurrency,
        terms: ['percent:privacy:2024-01-01', 'workplace:terms:2024-01-01']
      })
    }
  })
  const buttonDisabled = !dirty || !isValid || isSubmitting

  // this updates the default currency and ensures the currency is validated if required
  useEffect(() => {
    setFieldValue('preferredCurrency', accountConfig?.currency ? undefined : defaultCurrencyOption?.value)
    setFieldValue('showCurrencyOptions', showCurrencyOptions)
  }, [accountConfig?.currency, defaultCurrencyOption, showCurrencyOptions, setFieldValue])

  return (
    <>
      <OnboardingPopupModal
        displayOnboardingPopup={displayOnboardingPopup}
        showOnboardingPopupSkeleton={showOnboardingPopupSkeleton}
        handleSubmit={handleSubmit}
        touched={touched}
        errors={errors}
        error={error}
        handleChange={handleChange}
        isSubmitting={isSubmitting}
        buttonDisabled={buttonDisabled}
        handleBlur={handleBlur}
        values={values}
        currencyOptions={currencyOptions}
        setFieldValue={setFieldValue}
        defaultCurrencyOption={defaultCurrencyOption}
        showCurrencyOptions={showCurrencyOptions}
      />
      {wallet?.balance?.amount ? (
        <DonationWalletGiftPopup
          open={displayDonationWalletGiftPopup}
          handleClose={() => {
            setDisplayDonationWalletGiftPopup(false)
            document.body.setAttribute('style', 'overflow-y:auto')
          }}
          wallet={wallet.balance}
        />
      ) : null}
    </>
  )
}
