import { Box, Skeleton } from '@mantine/core'

export function OpportunityCardSkeleton() {
  return (
    <Box width="100%">
      <Skeleton variant="rectangular" height="150px" style={{ borderRadius: '0.5rem', marginBottom: '1rem' }} />
      <Skeleton variant="rectangular" height="24" style={{ borderRadius: '0.25rem', marginBottom: '0.5rem' }} />
      <Skeleton variant="rectangular" height="24" style={{ borderRadius: '0.25rem', marginBottom: '0.25rem' }} />
    </Box>
  )
}
