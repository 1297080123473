import { Box } from '@mantine/core'
import { Link } from 'react-router-dom-v6'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useTranslation } from 'react-i18next'
import { KeyboardEvent } from 'react'

import { Text, Tooltip } from '@percent/lemonade'
import classes from './LatestDonationItem.module.scss'
import { useDateTimeFmt, useMoneyFormat } from '@percent/workplace-giving/common/hooks'
import { useDonationForm } from '@percent/workplace-giving/common/hooks/useDonationForm/useDonationForm'
import { LatestDonation } from '@percent/workplace-giving/api/donation/latest-donations/get-latest-donations.types.'
import { FundraiserDonationLinkProps, OrganisationDonationButtonProps } from './LatestDonationItem.types'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

dayjs.extend(relativeTime)

function FundraiserDonationLink({ fundraiser, amount }: FundraiserDonationLinkProps) {
  const { moneyFormat } = useMoneyFormat()
  const { t } = useTranslation()

  return (
    <Link to={`/fundraisers/${fundraiser.id}`}>
      <Text size="small">{moneyFormat(amount)} </Text>
      <Text size="small" className={classes.OrgText}>
        {t('workplace_giving.latestDonations.donatedTo')} <span>{fundraiser.name}</span>
      </Text>
    </Link>
  )
}

function OrganisationDonationButton({ organisation, amount, handleDonate }: OrganisationDonationButtonProps) {
  const { moneyFormat } = useMoneyFormat()
  const { t } = useTranslation()

  const handleClick = () => {
    handleDonate(organisation.id)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleDonate(organisation.id)
    }
  }

  return (
    <Box role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleKeyDown} className={classes.OrgButton}>
      <Text size="small">{moneyFormat(amount)} </Text>
      <Text size="small" className={classes.OrgText}>
        {t('workplace_giving.latestDonations.donatedTo')} <span>{organisation.name}</span>
      </Text>
    </Box>
  )
}

export function LatestDonationsItem({ createdAt, organisation, fundraiser, amount }: LatestDonation) {
  const { theme } = useColorTheme()
  const { t } = useTranslation()
  const { handleDonate, DonationFormWrapper } = useDonationForm('regular')
  const { formatDateTime } = useDateTimeFmt()

  return (
    <>
      <Box className={classes.Wrapper}>
        <Box className={classes.DateWrapper}>
          <Tooltip content={formatDateTime(new Date(createdAt))}>
            <Box>
              <Text size="small">
                {dayjs().diff(createdAt, 'minute') <= 5
                  ? t('workplace_giving.time.justNow')
                  : dayjs(createdAt).fromNow()}
              </Text>
            </Box>
          </Tooltip>
        </Box>
        <Box className={classes.ContentWrapper}>
          {fundraiser ? (
            <FundraiserDonationLink fundraiser={fundraiser} amount={amount} />
          ) : (
            <OrganisationDonationButton organisation={organisation} amount={amount} handleDonate={handleDonate} />
          )}
        </Box>
      </Box>
      <DonationFormWrapper
        params={{
          primaryColor: theme.primary400
        }}
      />
    </>
  )
}
