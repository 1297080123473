import { Text, Card } from '@mantine/core'
import { clsx } from '@mantine/styles'

import { Icon } from '@percent/lemonade'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import classes from './SelectOptionCard.module.scss'

type SelectOptionCardProps = {
  testId: string
  icon?: IconProps['name']
  title: string
  description: string
  active: boolean
  onClick: VoidFunction
}

export function SelectOptionCard({
  testId,
  icon,
  title,
  description,
  active,
  onClick
}: Readonly<SelectOptionCardProps>) {
  return (
    <Card data-testid={testId} onClick={onClick} className={clsx(classes.Card, { [classes.Active]: active })}>
      {icon ? <Icon name={icon} size={6} color="primary400" /> : null}
      <Text className={classes.Title} style={{ marginTop: icon ? '1rem' : 0 }} component="p">
        {title}
      </Text>
      <Text className={classes.Description} component="p">
        {description}
      </Text>
    </Card>
  )
}
