import { Navigate, Outlet, useLocation } from 'react-router-dom-v6'
import { Box } from '@mantine/core'
import { ReactNode } from 'react'

import { OnboardingPopup } from '../app/Onboarding/OnboardingPopup/OnboardingPopup'
import { withWorkplaceGivingAccess } from '../common/hoc/withPermissions'
import { InsufficientPermissions } from '../app/Auth/InsufficientPermissions/InsufficientPermissions'
import { NavBar } from '../common/components'
import { DonationMatchRequestModal } from '../app/SidePanel/DonationMatchRequestModal/DonationMatchRequestModal'

import { Layout } from '@percent/workplace-giving/common/components/Layout/Layout'
import { useAuth } from '@percent/workplace-giving/common/hooks/useAuth/useAuth'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { Spinner } from '@percent/lemonade'

function OutletWithNavBar({ withoutLayout }: Readonly<RouteProps>) {
  return (
    <>
      {withoutLayout ? null : <NavBar />}
      <OnboardingPopup />
      <Outlet />
    </>
  )
}
const WGProtectedOutlet = withWorkplaceGivingAccess(OutletWithNavBar, InsufficientPermissions)

interface RouteProps {
  withoutLayout?: boolean
  children?: ReactNode
}

export function AllowAuthenticatedAccountOnlyRoute({ withoutLayout, children }: Readonly<RouteProps>) {
  const { state } = useAuth()
  const location = useLocation()

  if (state.status === 'loading' || state.status === 'first_load') {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Spinner size={8} />
      </Box>
    )
  }

  if (state.status === 'authorized') {
    if (withoutLayout) return <WGProtectedOutlet withoutLayout={withoutLayout} />

    return (
      <Layout>
        <WGProtectedOutlet withoutLayout={withoutLayout} />
        <DonationMatchRequestModal />
        {children}
      </Layout>
    )
  }

  return <Navigate to={AppRoute.SIGN_IN} replace state={{ from: location }} />
}
