import { ListResponse, QueryListFunction } from '../../goodstack'

import { GetSuggestedFundraisersQuery, SuggestedFundraisers } from './suggestedFundraisers.types'

export const getSuggestedFundraisers: QueryListFunction<
  string,
  GetSuggestedFundraisersQuery,
  ListResponse<SuggestedFundraisers>
> = (client, [_, query]) =>
  client
    .get<ListResponse<SuggestedFundraisers>>('/fundraisers/suggested', {
      params: {
        currency: query?.currency,
        country: query?.country,
        direction: query?.direction,
        pageSize: query?.pageSize
      },
      withCredentials: true
    })
    .then(r => r.data)
