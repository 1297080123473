import { useCallback, useMemo, useState } from 'react'
import { Alpha2Code } from 'i18n-iso-countries'
import { useDebounce } from 'usehooks-ts'
import identity from 'lodash/identity'
import { useTranslation } from 'react-i18next'
import type { FormikErrors } from 'formik'

import countries from '@percent/workplace-giving/i18n/countries'
import { FlagAvatar } from '@percent/lemonade'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { searchOrganisations } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations'
import { Organisation } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { createShortLink } from '@percent/utility'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { getVolunteeringActivities } from '@percent/workplace-giving/api/volunteering/getVolunteeringActivities/getVolunteeringActivities'
import { getAccountOpportunities } from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities'
import { config } from '@percent/workplace-giving/config/config'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'
import { VolunteeringTimeLogType } from '@percent/workplace-giving/api/opportunity/dto'
import { getShortLanguage } from '@percent/workplace-giving/utils/getShortLanguage'

export type LogVolunteeringActivityFormFields = Readonly<{
  date?: Date
  hours?: number
  minutes?: number
  organisationCountry?: string
  organisationId?: string
  activities?: string[]
  platform: VolunteeringTimeLogType
  opportunityId?: string
  skills: string[]
}>

export type UseLogVolunteeringActivityFormProps = Readonly<{
  values: LogVolunteeringActivityFormFields
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<LogVolunteeringActivityFormFields>>
  defaultOrg?: Organisation
}>

export type UseLogVolunteeringTimeResult = Readonly<{
  alpha3CountryCodes: SelectOption[]
  activitiesOptions: SelectOption[]
  isFetching: boolean
  isFetchingRegisteredOpportunities: boolean
  organisationQuery: string
  registeredOpportunitiesCount?: number
  registeredOpportunitiesQuery: string
  registeredOpportunitiesSearchResults: SelectOption[]
  resetOrgIdFieldAfterCountryChange: () => Promise<void>
  searchCountryCode: string
  searchResults: SelectOption[]
  setOrganisationQuery: (query: string) => void
  setSearchCountryCode: (countryCode: string) => void
  setRegisteredOpportunitiesQuery: (query: string) => void
  isLoading: boolean
  skillsOptions: SelectOption[]
  defaultCountryValue: SelectOption
  defaultOrganisationValue?: SelectOption
}>

export const useLogVolunteeringActivityForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  defaultOrg
}: UseLogVolunteeringActivityFormProps): UseLogVolunteeringTimeResult => {
  const { t, i18n } = useTranslation()

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, getShortLanguage(i18n)),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    [t, i18n]
  )

  const defaultCountryValue = useMemo(() => {
    if (defaultOrg?.countryCode) {
      return alpha3CountryCodes.find(a => a.value === defaultOrg?.countryCode)
    }

    return alpha3CountryCodes.find(a => a.value === values.organisationCountry)
  }, [alpha3CountryCodes, defaultOrg?.countryCode, values.organisationCountry])

  const { data: activitiesData, isLoading: isLoadingActivities } = useQuery(
    ['getVolunteeringActivities'],
    getVolunteeringActivities
  )

  const activitiesOptions = useMemo(() => {
    if (activitiesData) {
      return activitiesData
        .map(activity => ({
          value: activity.id,
          label: activity.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    return []
  }, [activitiesData])

  const skillsOptions = useMemo(() => {
    return config.skills
      .map(skill => ({
        value: skill.id,
        label: t(`workplace_giving.volunteering.skills.${skill.id}` as LocaleKey, {
          defaultValue: skill.name
        })
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [t])

  const resetOrgIdFieldAfterCountryChange = useCallback(async () => {
    await setFieldValue('organisationId', '')
    await setFieldTouched('organisationId', false, true)
  }, [setFieldTouched, setFieldValue])

  const [organisationQuery, setOrganisationQuery] = useState<string>('')
  const [searchCountryCode, setSearchCountryCode] = useState<string>(defaultCountryValue!.value)

  const debouncedOrganisationQuery = useDebounce(organisationQuery, 200)
  const { data: searchData, isFetching } = useQuery(
    ['searchNonProfits', { query: debouncedOrganisationQuery, pageSize: 10, countryCode: searchCountryCode }],
    searchOrganisations,
    { enabled: !!debouncedOrganisationQuery }
  )
  const shortLanguage = getShortLanguage(i18n)

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('workplace_giving.common.id')}: ${organisation.registryId}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const defaultOrganisationValue = useMemo(
    () =>
      defaultOrg
        ? {
            value: defaultOrg.id,
            label: defaultOrg.name,
            description: getOrganisationDescription(defaultOrg)
          }
        : undefined,
    [defaultOrg, getOrganisationDescription]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return searchData
      ? searchData.data.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [searchData, getOrganisationDescription])

  const { data: fetchRegisteredOpportunitiesCountResponse, isLoading: isLoadingRegisteredOpportunitiesCount } =
    useQuery(
      ['getAccountOpportunitiesCount', { pageSize: 1, participationStatus: ['registered', 'confirmed'] }],
      getAccountOpportunities,
      { staleTime: Infinity }
    )
  const registeredOpportunitiesCount = fetchRegisteredOpportunitiesCountResponse?.totalResults

  const [registeredOpportunitiesQuery, setRegisteredOpportunitiesQuery] = useState<string>('')

  const debouncedRegisteredOpportunitiesQuery = useDebounce(registeredOpportunitiesQuery, 200)
  const { data: registeredOpportunitiesData, isFetching: isFetchingRegisteredOpportunities } = useQuery(
    [
      'getAccountOpportunities',
      { query: debouncedRegisteredOpportunitiesQuery, pageSize: 10, participationStatus: ['registered', 'confirmed'] }
    ],
    getAccountOpportunities,
    { enabled: !!registeredOpportunitiesCount && !!debouncedRegisteredOpportunitiesQuery }
  )
  const registeredOpportunitiesSearchResults: SelectOption[] = useMemo(() => {
    return registeredOpportunitiesData
      ? registeredOpportunitiesData.data.map(registeredOpportunity => ({
          value: registeredOpportunity.opportunity.id,
          label: registeredOpportunity.opportunity.name
        }))
      : []
  }, [registeredOpportunitiesData])

  return {
    registeredOpportunitiesCount,
    alpha3CountryCodes,
    activitiesOptions,
    isFetching,
    isFetchingRegisteredOpportunities,
    organisationQuery,
    registeredOpportunitiesQuery,
    registeredOpportunitiesSearchResults,
    resetOrgIdFieldAfterCountryChange,
    searchCountryCode,
    searchResults,
    setOrganisationQuery,
    setSearchCountryCode,
    setRegisteredOpportunitiesQuery,
    isLoading: isLoadingActivities || isLoadingRegisteredOpportunitiesCount,
    skillsOptions,
    defaultCountryValue: defaultCountryValue!,
    defaultOrganisationValue
  }
}
