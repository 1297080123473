import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useMoneyFormat } from '../../hooks/useMoneyFormat/useMoneyFormat'
import coinIcon from '../../../assets/icons/coin.svg'

import classes from './WalletBalance.module.scss'
import { WalletAmountProps } from './WalletBalance.types'
import { Tooltip } from '@percent/lemonade'

export function WalletBalance({ balance }: Readonly<WalletAmountProps>) {
  const { moneyFormat } = useMoneyFormat()
  const { t } = useTranslation()

  return (
    <Tooltip content={t('workplace_giving.tooltip.wallet')} placement="top-end">
      <Box className={classes.WalletAmountContainer}>
        <img src={coinIcon} alt="" />
        {t('workplace_giving.myImpact.donationBadge.wallet')}
        <span data-testid="wallet-balance">{moneyFormat(balance, { hideDecimalsIfZero: true })}</span>
      </Box>
    </Tooltip>
  )
}
